import { deleteCompanyApi, fetchCompanyData, upsertCompanyData } from 'api';
import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import React, { useEffect, useState } from 'react';
import { useModal, InputField, Button } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { Table, TableContainer } from 'components/Table/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PageLoading from 'components/common/PageLoading';
import EditIcon from 'images/edit-icon.png';
import DeleteIcon from 'images/bin.png';
import { ErrorAlert, SuccessAlert } from 'components/common/Alert';
import { useToast } from 'components/common/Toast';
import { useTranslation } from 'react-i18next';
import { SwitchBox } from 'components/common/SwitchBox';

const AddButton = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: 25px;
`;

const FormContainer = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  margin-top: -50px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Form = styled.form`
  display: flex;
  width: 500px;
  height: auto;
  flex-direction: column;
  gap: 10px;
`;

const FormButtonWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

interface ServiceTitan {
  companyID: string;
  clientID: string;
  tenantID: string;
  clientSecret: string;
}

interface CompanyData {
  id: number;
  companyName: string;
  serviceTitanSecrets: ServiceTitan;
}

const TableComponent = ({ setState, openModal, companyData, openDelModal, setDelComp }) => {
  return (
    <TableContainer>
      <Table cellPadding='0' cellSpacing='0'>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>ID</th>
            <th>Tenant ID</th>
            <th>Client ID</th>
            <th>Client Secret</th>
            <th>Pro Portal Enabled</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {companyData.map(node => {
            const {
              companyName,
              id,
              proPortalEnabled,
              serviceTitanSecrets: { companyID, tenantID, clientID, clientSecret },
            } = node;
            return (
              <tr key={id}>
                <td>{companyName}</td>
                <td>{companyID?.substr(0, 25)}...</td>
                <td>{tenantID}</td>
                <td>{clientID}</td>
                <td>{clientSecret?.substr(0, 20)}...</td>
                <td style={{ textAlign: 'center' }}>{proPortalEnabled ? 'Yes' : 'No'}</td>
                <td>
                  <img
                    onClick={() => {
                      setState(node);
                      openModal();
                    }}
                    width={'15px'}
                    height={'15px'}
                    src={EditIcon}
                    title='Edit'
                    alt='edit icon'
                  />
                </td>
                <td>
                  <img
                    onClick={() => {
                      setDelComp(node);
                      openDelModal();
                    }}
                    width={'15px'}
                    height={'15px'}
                    title='Delete'
                    src={DeleteIcon}
                    alt='edit icon'
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableContainer>
  );
};

const DetailsSchema = Yup.object().shape({
  companyName: Yup.string().required(),
  companyID: Yup.string().required(),
  tenantID: Yup.string().required(),
  clientID: Yup.string().required(),
  clientSecret: Yup.string().required(),
  proPortalEnabled: Yup.boolean().required(),
});

const state = {
  companyName: '',
  tenantID: '',
  companyID: '',
  clientID: '',
  clientSecret: '',
  proPortalEnabled: false,
};

interface RegistrationFormProps {
  closeModal: any;
  state?: any;
  setCompanyData: any;
}

const RegistrationForm = ({ closeModal, state: data = {}, setCompanyData }: RegistrationFormProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { serviceTitanSecrets = {} } = data;

  function handleCancel() {
    closeModal();
  }

  async function upsertFormData(e) {
    closeModal();
    try {
      const response = await upsertCompanyData(e);
      setCompanyData(prevState => {
        return prevState.filter(node => node.id !== response.id).concat(response);
      });
      toast(SuccessAlert(t, 'Update successful!'));
    } catch (error) {
      toast(ErrorAlert(t, 'Update failed!'));
    }
  }

  return (
    <div className='bp4-dialog-container'>
      <FormContainer>
        <h1>New Company Data</h1>
        <Formik
          validationSchema={DetailsSchema}
          initialValues={{ ...state, ...data, ...serviceTitanSecrets }}
          onSubmit={e => {
            upsertFormData(e);
          }}>
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            values: { companyName, companyID, tenantID, clientID, clientSecret, proPortalEnabled },
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <InputField
                  label='Company Name'
                  type='text'
                  required
                  name='companyName'
                  onChange={handleChange}
                  value={companyName}
                />
                <InputField
                  label='Company ID'
                  required
                  type='text'
                  name='companyID'
                  onChange={handleChange}
                  value={companyID}
                />
                <InputField
                  label='Tenant ID'
                  required
                  type='text'
                  name='tenantID'
                  onChange={handleChange}
                  value={tenantID}
                />
                <InputField
                  label='Client ID'
                  required
                  type='text'
                  name='clientID'
                  onChange={handleChange}
                  value={clientID}
                />
                <InputField
                  label='Client Secret'
                  required
                  type='text'
                  name='clientSecret'
                  onChange={handleChange}
                  value={clientSecret}
                />
                <SwitchBox
                  styles={{ marginLeft: '12px' }}
                  label='Pro Portal integration'
                  name='proPortalEnabled'
                  checked={proPortalEnabled}
                  onChange={() => setFieldValue('proPortalEnabled', !proPortalEnabled)}
                />
                <FormButtonWrapper>
                  <Button type='button' onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type='submit'>Save</Button>
                </FormButtonWrapper>
              </Form>
            );
          }}
        </Formik>
      </FormContainer>
    </div>
  );
};

const DeleteModal = ({ closeModal, state: data = {}, setDelComp, setState }: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { id, companyName } = data;

  function handleCancel() {
    closeModal();
  }

  async function deleteCompany() {
    try {
      await deleteCompanyApi(id);
      setState(prevState => {
        return prevState.filter(node => node.id !== id);
      });
      toast(SuccessAlert(t, 'Delete successful!'));
    } catch (error) {
      console.log(error);
      toast(ErrorAlert(t, 'Update failed!'));
    } finally {
      closeModal();
      setDelComp();
    }
  }

  return (
    <div
      style={{
        textAlign: 'center',
      }}
      className='bp4-dialog-container'>
      <p
        style={{
          marginBottom: '40px',
        }}>
        Are you sure you want to delete the configuration for {companyName}?
      </p>
      <Button
        style={{
          marginRight: '10px',
        }}
        variant='secondary'
        onClick={() => {
          setDelComp(undefined);
          handleCancel();
        }}>
        Cancel
      </Button>
      <Button
        onClick={() => {
          deleteCompany();
        }}
        variant='primary'>
        Delete
      </Button>
    </div>
  );
};

const Configurations = () => {
  const [companyData, setCompanyData] = useState<CompanyData[]>([]);
  const [deleteComp, setDelComp] = useState<any>();
  const [loading, setLoading] = useState(false);

  const { Modal, openModal, closeModal } = useModal({
    newDesign: true,
  });

  const { Modal: DelModal, openModal: openDelModal, closeModal: closeDelModal } = useModal({
    newDesign: true,
  });

  const [state, setState] = useState();

  useEffect(() => {
    async function CompanyData() {
      setLoading(true);
      const data = await fetchCompanyData();
      setCompanyData(data);
      setLoading(false);
    }
    CompanyData();
  }, []);

  if (loading) return <PageLoading />;

  return (
    <DashboardLayout>
      <Modal>
        <RegistrationForm setCompanyData={setCompanyData} state={state} closeModal={closeModal} />
      </Modal>
      <DelModal>
        <DeleteModal setDelComp={setDelComp} setState={setCompanyData} state={deleteComp} closeModal={closeDelModal} />
      </DelModal>
      <AddButton>
        <Button
          variant={'primary'}
          onClick={() => {
            setState(undefined);
            openModal();
          }}>
          Add
        </Button>
      </AddButton>
      <TableComponent
        setDelComp={setDelComp}
        openDelModal={openDelModal}
        companyData={companyData}
        openModal={openModal}
        setState={setState}
      />
    </DashboardLayout>
  );
};

export default Configurations;
