import { HomeownerProgress, ProCustomerProgress, Status } from './interfaces';

export const progressMsg = {
  createUser: 'Creating user account...',
  createLocation: 'Creating location...',
  createDevice: 'Registering device...',
  getActivationId: 'Getting user activation ID...',
  activeUser: 'Activating user...',
};

export const proCustomerProgressMsg = {
  createCustomer: 'Creating customer account...',
  createLocation: 'Creating location...',
  addDeviceToLocation: 'Adding device to location...',
  addDeviceToService: 'Adding service(s) to device...',
};

export const homeownerInitProgress: HomeownerProgress = {
  createUser: {
    status: Status.Pending,
    message: progressMsg.createUser,
  },
  createLocation: {
    status: Status.Pending,
    message: progressMsg.createLocation,
  },
  createDevice: {
    status: Status.Pending,
    message: progressMsg.createDevice,
  },
  getActivationId: {
    status: Status.Pending,
    message: progressMsg.getActivationId,
  },
  activeUser: {
    status: Status.Pending,
    message: progressMsg.activeUser,
  },
};

export const proCustomerInitProgress: ProCustomerProgress = {
  createCustomer: {
    status: Status.Pending,
    message: proCustomerProgressMsg.createCustomer,
  },
  createLocation: {
    status: Status.Pending,
    message: proCustomerProgressMsg.createLocation,
  },
  addDeviceToLocation: {
    status: Status.Pending,
    message: proCustomerProgressMsg.addDeviceToLocation,
  },
  addDeviceToService: {
    status: Status.Pending,
    message: proCustomerProgressMsg.addDeviceToService,
  },
};
