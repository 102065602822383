import React from 'react';
import { ButtonWrapper, ButtonContainer } from '../styles';
import { useHistory } from 'react-router-dom';

export const ProgressesResult = ({ regDeviceState, resetForm, selectedPartnerCustomerId }) => {
  const history = useHistory();

  return (
    <ButtonWrapper>
      <ButtonContainer>
        <div
          onClick={() => {
            resetForm();
          }}>
          Register another device
        </div>
      </ButtonContainer>
      {regDeviceState.proPortalEnabled && (
        <ButtonContainer>
          <a
            href={`${process.env.REACT_APP_PRO_PORTAL_URL}/mybusiness/customers/account-lookup/${selectedPartnerCustomerId}`}
            target='_blank'
            rel='noreferrer'>
            View Pro Customer Account
          </a>
        </ButtonContainer>
      )}
      <ButtonContainer>
        <div onClick={() => history.push('/')}>Go to completed registrations</div>
      </ButtonContainer>
    </ButtonWrapper>
  );
};
