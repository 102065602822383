export const KEY_IO_CLIENT_USERNAME = 'ws-client-username';

export enum IOEvents {
  CreateDevice = 'createDevice',
  AddDevice = 'addDevice',
}

export enum IOEventTypes {
  CheckConnection = 'CHECK_CONNECTION',
  ConfirmConnection = 'CONFIRM_CONNECTION',
  CreateStart = 'CREATE_START',
  CreateUser = 'CREATE_USER_TO_REG_DEVICE',
  CreateLocation = 'CREATE_LOCATION_TO_REG_DEVICE',
  CreateDevice = 'CREATE_DEVICE_TO_REG_DEVICE',
  GetActivationId = 'GET_ACTIVATION_ID_TO_REG_DEVICE',
  ActiveUser = 'ACTIVE_USER_TO_REG_DEVICE',
  SaveDeviceInDB = 'SAVE_DEVICE_IN_DB',
  DeleteUser = 'DELETE_USER_IN_REG_DEVICE',
  GetException = 'GET_EXCEPTION',
}

export enum IOStatus {
  Started = 'STARTED',
  Failed = 'FAILED',
  Done = 'DONE',
}
