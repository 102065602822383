import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { Socket } from 'socket.io-client';

const Context = createContext({} as any);

type UseWebSocketDataType = {
  webSocketData: WebSocketContextDataType;
  setWebSocketData: Dispatch<SetStateAction<WebSocketContextDataType>>;
};

export const useWebSocketData = (): UseWebSocketDataType => {
  const data = useContext<UseWebSocketDataType>(Context);
  return data;
};

type WebSocketContextDataType = {
  username: string | null;
  WebSocketClient: Socket | null;
  isConnected: boolean;
};

export const WebSocketContext = ({ children }) => {
  const [webSocketData, setWebSocketData] = useState<WebSocketContextDataType>({
    username: null,
    WebSocketClient: null,
    isConnected: false,
  });

  return (
    <Context.Provider
      value={{
        webSocketData,
        setWebSocketData,
      }}>
      {children}
    </Context.Provider>
  );
};
