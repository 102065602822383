import React from 'react';
import {
  HomeownerContainer,
  ProgressTitle,
  ProgressRow,
  ProgressMessage,
  ProgressStatus,
  ProgressResult,
  ResultStatus,
  ResultMessage,
} from 'components/Registration/styles';
import { StatusIcon } from './StatusIcon';

export const HomeownerProgresses = ({ homeownerProgress, homeownerResult }) => {
  return (
    <HomeownerContainer>
      <ProgressTitle>Homeowner Account and Device Registration</ProgressTitle>
      {Object.keys(homeownerProgress).map((step, index) => (
        <ProgressRow key={index}>
          <ProgressMessage>{homeownerProgress[step].message}</ProgressMessage>
          <ProgressStatus>{<StatusIcon status={homeownerProgress[step].status} />}</ProgressStatus>
        </ProgressRow>
      ))}

      <ProgressResult>
        <ResultStatus>{homeownerResult.status}</ResultStatus>
        <ResultMessage>{homeownerResult.message}</ResultMessage>
      </ProgressResult>
    </HomeownerContainer>
  );
};
