import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import React, { useEffect, useState } from 'react';
import { getRegistrations } from 'api';
import moment from 'moment-timezone';
import { compareDate, getDecrementedDate, getIncrementedDate, isoDateToMilliseconds, toUTCTime } from 'utils/datetime';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useToast } from 'components/common/Toast';
import { ErrorAlert, WarningAlert } from 'components/common/Alert';
import { useCustomAuthData } from 'components/AuthContext';
import { getStatusAndText } from 'utils/status';
import { DevicesHeader, DevicesTable, DevicesFilter } from 'components/PreRegistration';
import { RegistrationData } from 'components/PreRegistration/interfaces';

const defaultStartDate = getDecrementedDate(14, new Date().toISOString());

export const INIT_DATE_RANGE = {
  startDate: defaultStartDate,
  endDate: '',
};

const INIT_DEVICE_STATUS_FILTER = [];

const PreRegistrations = () => {
  const [records, setRecords] = useState<Array<RegistrationData>>([]);
  const { isResideoAdmin } = useCustomAuthData();
  const [sortNameAsc, setSortNameAsc] = useState(true);
  const [deviceStatus, setDeviceStatus] = useState<any>(INIT_DEVICE_STATUS_FILTER);
  const [loadingStatus] = useState(false);
  const [sortDateAsc, setSortDateAsc] = useState(false);
  const [sortJobNumAsc, setSortJobNumAsc] = useState(true);
  const [sortParam, setSortParam] = useState('createdAt');

  const [recordsFilteredByStatus, setRecordsFilteredByStatus] = useState<Array<RegistrationData>>([]);

  const [dateRange, setDateRange] = useState<{ startDate: string; endDate: string }>(INIT_DATE_RANGE);
  const [searchStr, setSearchStr] = useState<string>('');

  const { t } = useTranslation();
  const toast = useToast();

  const [loading, setLoading] = useState(true);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);

  const sortByDate = (data?: any) => {
    const arrToSort = cloneDeep(data || records);
    const sortOrder = data ? false : !sortDateAsc;
    const sorted = arrToSort.sort((a, b) => {
      const dateA = isoDateToMilliseconds(a.createdAt);
      const dateB = isoDateToMilliseconds(b.createdAt);
      const order = sortOrder ? dateA - dateB : dateB - dateA;
      return order;
    });
    if (!data) {
      setRecords(sorted);
      setSortDateAsc(!sortDateAsc);
      setSortJobNumAsc(true);
      setSortNameAsc(true);
    }
    return sorted;
  };

  const sortByTechnicianNames = () => {
    const sorted = records.sort(({ technicianName: a }, { technicianName: b }) => {
      if (!sortNameAsc) {
        return a.localeCompare(b);
      }
      return b.localeCompare(a);
    });
    setRecords(sorted);
    setSortNameAsc(!sortNameAsc);
    setSortDateAsc(true);
    setSortJobNumAsc(true);
  };

  const sortByJobNumbers = () => {
    const sorted = records.sort((a, b) =>
      !sortJobNumAsc ? a.serviceTitanJobID - b.serviceTitanJobID : b.serviceTitanJobID - a.serviceTitanJobID
    );
    setRecords(sorted);
    setSortJobNumAsc(!sortJobNumAsc);
    setSortDateAsc(true);
    setSortNameAsc(true);
  };

  const clearFilters = () => {
    setDateRange(INIT_DATE_RANGE);
    setDeviceStatus(INIT_DEVICE_STATUS_FILTER);
  };

  const COLUMNS = [
    {
      label: 'Pre-Registration Date',
      onClickHandler: () => {
        sortByDate();
        setSortParam('createdAt');
      },
      key: 'createdAt',
      caretState: sortDateAsc,
    },
    {
      label: 'Job #',
      onClickHandler: () => {
        sortByJobNumbers();
        setSortParam('serviceTitanJobID');
      },
      key: 'serviceTitanJobID',
      caretState: sortJobNumAsc,
    },
    {
      label: 'Customer Name & Location',
    },
    {
      label: 'Device Registration',
    },
    {
      label: 'Device MAC',
    },
    {
      label: 'Technician',
      onClickHandler: () => {
        sortByTechnicianNames();
        setSortParam('technicianName');
      },
      key: 'technicianName',
      caretState: sortNameAsc,
    },
    {
      label: 'Status',
    },
  ];

  const isTest = typeof window !== 'undefined' && localStorage.getItem('testmode');

  const filterByStatus = () => {
    const recWithStatusResult = records.filter(({ status }) => {
      if (!deviceStatus.length) return true;
      const res = getStatusAndText(status).status;
      return deviceStatus.map(({ value }) => value).includes(res);
    });
    setRecordsFilteredByStatus([...recWithStatusResult]);
  };

  useEffect(() => {
    filterByStatus();
  }, [records, deviceStatus, currentPage]);

  useEffect(() => {
    getRecords(true);
  }, [searchStr, dateRange]);

  const getRecords = async (calledProgrammatically?: boolean) => {
    if (!compareDate(dateRange.startDate, dateRange.endDate)) {
      toast(WarningAlert(t, 'End Date must be on or after the Start Date.'));
      return;
    }

    try {
      calledProgrammatically && setLoading(true);

      const result = await getRegistrations(
        toUTCTime(dateRange.startDate),
        toUTCTime(getIncrementedDate(1, dateRange.endDate)),
        searchStr
      );
      if (result.data.records) {
        const recordsWithLocalTimezone = result.data.records.map(node => ({
          ...node,
          createdAt: moment(node.createdAt)
            .local()
            .toISOString(),
        }));
        const sortedRecords = sortByDate(recordsWithLocalTimezone);
        setRecords(sortedRecords);
      } else {
        setRecords([]);
      }
      setCurrentPage(1);

      setLoading(false);
    } catch (error) {
      setRecords([]);
      console.error(error);
      setLoading(false);
      const errorMessage = error?.['response']?.['data']?.['message'];
      if (errorMessage === 'Company not found!') {
        toast(WarningAlert(t, 'A company with the given ID does not exist.'));
      } else {
        toast(ErrorAlert(t, 'Something went wrong!'));
      }
    }
  };

  return (
    <DashboardLayout>
      <DevicesFilter
        filterByString={{
          setValue: setSearchStr,
        }}
        filterByDate={{
          setDateRange,
          clearFilters,
        }}
        setStatus={setDeviceStatus}
      />
      <DevicesHeader filterByStatus={deviceStatus} setStatus={setDeviceStatus} />
      <DevicesTable
        isLoading={loading}
        columns={COLUMNS}
        header={{
          isResideoAdmin,
          isTest,
          sortParam,
        }}
        body={{
          loadingStatus,
          data: recordsFilteredByStatus,
        }}
        page={{
          currentPage,
          setCurrentPage,
        }}
      />
    </DashboardLayout>
  );
};

export default PreRegistrations;
