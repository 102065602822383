import React from 'react';
import { TableBody, TableHead } from './Table';
import { Table, TableContainer, TableScrollView } from './Table/styles';
import { TableTitle } from './styles';

export const SyncStatusTable = ({ columns, header: { sortParam }, body: { data } }) => {
  return (
    <TableContainer>
      <TableTitle>Sync status</TableTitle>
      <TableScrollView>
        <Table cellPadding='0' cellSpacing='0'>
          <TableHead columns={columns} sortParam={sortParam} />
          <TableBody records={data} columns={columns} />
        </Table>
      </TableScrollView>
    </TableContainer>
  );
};
