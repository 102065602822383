import React, { useState } from 'react';
import { Button } from '@resideo/blueprint-react';
import moment from 'moment-timezone';
import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import { DevicesFilter, DevicesTable } from 'components/ProPortalBulkSync';
import {
  Content,
  SyncStatusTotalContent,
  SyncStatusTotalLabel,
  SyncStatusTotalTitle,
  SyncStatusTotalValue,
  SyncStatusTotalWrapper,
} from 'components/ProPortalBulkSync/styles';
import { SyncStatusTable } from 'components/ProPortalBulkSync/SyncStatusTable';
import { getRegistrationStatus, getRegistrations, updateDevicePartnerCustomerId } from 'api';
import { compareDate, formatDate, getCurrentFormattedTime, getIncrementedDate, toUTCTime } from 'utils/datetime';
import { useToast } from 'components/common/Toast';
import { ErrorAlert } from 'components/common/Alert';
import { useTranslation } from 'react-i18next';
import { RegistrationData, SyncFailure, SyncStatus } from 'components/ProPortalBulkSync/interfaces';
import { Spinner } from '@resideo/blueprint-react';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  PartnerCustomerCreateMutation,
  PartnerDeviceLocationCreateMutation,
  InstallationPartnerDeviceServiceCreateMutation,
  PartnerCustomersQuery,
  Address,
  BrandingPartnerDeviceServiceCreateMutation,
  ComfortDeviceHandoffToConsumerMutation,
  PartnerCustomer,
  UseDeviceQuery,
  LyricThermostatDevice,
  DeviceByNodeIdQuery,
  PartnerDeviceLocationQuery,
  DeviceServices,
} from '__generated__/graphql';
import PartnerCustomerCreate from 'graphql/PartnerCustomerCreateMutation';
import PartnerDeviceLocationCreate from 'graphql/PartnerDeviceLocationCreateMutation';
import InstallationPartnerDeviceServiceCreate from 'graphql/InstallationPartnerDeviceServiceCreateMutation';
import BrandingPartnerDeviceServiceCreate from 'graphql/BrandingPartnerDeviceServiceCreateMutation';
import GetPartnerCustomers from 'graphql/PartnerCustomersQuery';
import GetDeviceByNodeId from 'graphql/DeviceByNodeIdQuery';
import ComfortDeviceHandoffToConsumer from 'graphql/ComfortDeviceHandoffToConsumerMutation';
import { UseDevice } from 'hooks/useDeviceQuery';
import GetPartnerDeviceLocation from 'graphql/PartnerDeviceLocationQuery';
import { SyncFailureTable } from 'components/ProPortalBulkSync/SyncFailureTable';
interface PartnerDeviceLocation {
  address: Address;
  partnerCustomerId: string;
  id: string;
}

const defaultEndDate = formatDate(new Date().getTime());
export const INIT_DATE_RANGE = {
  startDate: '',
  endDate: defaultEndDate,
};

const SYNC_STATUS = {
  blank: '',
  created: 'Created',
  added: 'Added',
  exists: 'Exists',
  applied: 'Applied',
  success: 'Success',
  na: 'NA',
  skipped: 'Skipped',
  error: 'Error',
  processing: <Spinner color='primary' style={{ marginTop: '2px' }} />,
};

const STATUS_TYPE = {
  customer: 'customer',
  location: 'location',
  device: 'device',
  service: 'service',
  status: 'status',
};

const DEVICE_COLUMNS = [
  {
    label: 'DB ID',
    key: 'id',
    width: '10%',
  },
  {
    label: 'Created',
    key: 'createdAt',
    type: 'date',
    width: '30%',
  },
  {
    label: 'Device Registration',
    key: 'hhEmail',
    width: '30%',
  },
  {
    label: 'Device MAC',
    key: 'macID',
    width: '30%',
  },
];

const SYNC_STATUS_COLUMNS = [
  {
    label: 'DB ID',
    key: 'id',
    width: '10%',
  },
  {
    label: 'Customer',
    key: STATUS_TYPE.customer,
    width: '18%',
  },
  {
    label: 'Location',
    key: STATUS_TYPE.location,
    width: '18%',
  },
  {
    label: 'Device',
    key: STATUS_TYPE.device,
    width: '18%',
  },
  {
    label: 'Service',
    key: STATUS_TYPE.service,
    width: '18%',
  },
  {
    label: 'Status',
    key: STATUS_TYPE.status,
    width: '18%',
  },
];

const initTotalSyncStatus = { successCount: 0, errorCount: 0, skipCount: 0 };
const ProPortalBulkSync = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const [{ partnerAccountId, partnerAccount }] = useCurrentUser();

  const [loadingDevices, setLoadingDevices] = useState(false);
  const [devices, setDevices] = useState<RegistrationData[]>([]);
  const [syncStatuses, setSyncStatuses] = useState<SyncStatus[]>([]);
  const [syncFailures, setSyncFailures] = useState<SyncFailure[]>([]);

  const [createPartnerCustomer] = useMutation<PartnerCustomerCreateMutation>(PartnerCustomerCreate);
  const [createPartnerDeviceLocation] = useMutation<PartnerDeviceLocationCreateMutation>(PartnerDeviceLocationCreate);
  const [installPartnerDevice] = useMutation<InstallationPartnerDeviceServiceCreateMutation>(
    InstallationPartnerDeviceServiceCreate
  );
  const [addBrandingPartnerDeviceServiceInstallation] = useMutation<BrandingPartnerDeviceServiceCreateMutation>(
    BrandingPartnerDeviceServiceCreate
  );
  const [comfortDeviceHandoffToConsumer] = useMutation<ComfortDeviceHandoffToConsumerMutation>(
    ComfortDeviceHandoffToConsumer
  );
  const [getPartnerCustomers] = useLazyQuery<PartnerCustomersQuery>(GetPartnerCustomers);
  const [getPartnerDeviceLocation] = useLazyQuery<PartnerDeviceLocationQuery>(GetPartnerDeviceLocation);
  const [getDeviceByNodeId] = useLazyQuery<DeviceByNodeIdQuery>(GetDeviceByNodeId);
  const [getDeviceByMacId] = useLazyQuery<UseDeviceQuery>(UseDevice);

  const [totalSyncStatus, setTotalSyncStatus] = useState<{
    successCount: number;
    errorCount: number;
    skipCount: number;
  }>(initTotalSyncStatus);

  const getDevices = async dateRange => {
    if (!dateRange.startDate) {
      toast(ErrorAlert(t, 'Start Date is Required'));
      return;
    } else if (!dateRange.endDate) {
      toast(ErrorAlert(t, 'End Date is Required'));
      return;
    } else if (!compareDate(dateRange.startDate, dateRange.endDate)) {
      toast(ErrorAlert(t, 'End Date must be later than Start Date'));
      return;
    }

    setLoadingDevices(true);
    setDevices([]);
    try {
      const result = await getRegistrations(
        toUTCTime(dateRange.startDate),
        toUTCTime(getIncrementedDate(1, dateRange.endDate)),
        '',
        'desc',
        0
      );
      if (result.data.records) {
        const recordsWithLocalTimezone = result.data.records.map(node => ({
          ...node,
          createdAt: moment(node.createdAt)
            .local()
            .toISOString(),
        }));
        setDevices(recordsWithLocalTimezone);
      }
    } catch (e) {
      toast(ErrorAlert(t, 'An error occurred while fetching devices in the database.'));
    }
    setLoadingDevices(false);
  };

  const checkAddressMatch = (locations: PartnerDeviceLocation[], homeownerAddress: string) => {
    // Function to extract the first number from an address string
    const extractFirstNumber = (str: string): number | null => {
      const match = str.match(/\d+/);
      return match ? parseInt(match[0]) : null;
    };

    // Extract the house number from the homeowner's address
    const homeownerHouseNumber = extractFirstNumber(homeownerAddress.split(', ')[0]) || null;

    // Find a location with a matching house number
    const match = locations.find(location => {
      if (!homeownerHouseNumber) return false;
      const locationHouseNumber = extractFirstNumber(`${location.address.addressLine1}`);
      return homeownerHouseNumber === locationHouseNumber;
    });

    return match;
  };

  const processCustomer = (
    device: RegistrationData
  ): Promise<{
    exists: boolean;
    data: { partnerCustomer: PartnerCustomer };
  }> => {
    return new Promise((resolve, reject) => {
      getPartnerCustomers({
        variables: {
          partnerAccountNodeId: partnerAccountId,
          filterBy: [
            {
              email: device.hhEmail,
            },
          ],
        },
        fetchPolicy: 'network-only',
        onCompleted: ({ partnerCustomers }) => {
          if (partnerCustomers.edges.length) {
            // Update device with PartnerCustomerAccountId
            updateDevicePartnerCustomerId(device.id, partnerCustomers.edges[0].node.id as string)
              .then(() => {
                resolve({
                  exists: true,
                  data: {
                    partnerCustomer: partnerCustomers.edges[0].node as PartnerCustomer,
                  },
                });
              })
              .catch(err => {
                reject(err);
              });
          } else {
            // Create Customer Account if not exists
            createPartnerCustomer({
              variables: {
                input: {
                  address: {
                    addressLine2: device.locationName,
                    addressLine1: device.homeownerAddress,
                    city: device.homeownerCity,
                    contactName: device.technicianName,
                    countryCode: 'US',
                    mailingAddressName: `${device.homeownerAddress}, ${device.homeownerCity}, ${device.homeownerState}, ${device.homeownerZip}`,
                    stateProvinceRegionCode: device.homeownerState,
                    zipPostalCode: device.hhZip,
                  },
                  email: device.hhEmail,
                  firstName: device.hhFirstName,
                  lastName: device.hhLastName,
                  partnerAccountNodeId: partnerAccountId,
                  phoneNumber: '',
                },
              },
              onCompleted: ({ partnerCustomerCreate }) => {
                if (partnerCustomerCreate) {
                  // Update DB partnerCustomerAccountId
                  updateDevicePartnerCustomerId(device.id, partnerCustomerCreate?.partnerCustomer?.id as string)
                    .then(() => {
                      resolve({
                        exists: false,
                        data: {
                          partnerCustomer: partnerCustomerCreate?.partnerCustomer as PartnerCustomer,
                        },
                      });
                    })
                    .catch(err => {
                      reject(err);
                    });
                } else {
                  reject(new Error('Partner customer creation failed.'));
                }
              },
              onError: error => {
                console.error(error.message || error);
                reject(error);
              },
            });
          }
        },
        onError: error => {
          console.error(error);
          reject(error);
        },
      });
    });
  };
  const processLocation = (
    partnerCustomer: PartnerCustomer,
    device: RegistrationData
  ): Promise<{ exists: boolean; locationId: string }> => {
    return new Promise((resolve, reject) => {
      const locations: PartnerDeviceLocation[] = partnerCustomer.deviceLocations?.edges
        .map(locationEdge => {
          const address = locationEdge?.node?.['address'];
          const locationId = locationEdge?.node?.id;
          return {
            address,
            partnerCustomerId: partnerCustomer.id,
            id: locationId,
          };
        })
        .filter(el => el.id && el.address) as PartnerDeviceLocation[];

      const matchedLocation = checkAddressMatch(locations, device.homeownerAddress);
      if (matchedLocation) {
        resolve({
          exists: true,
          locationId: matchedLocation.id,
        });
      } else {
        // create
        createPartnerDeviceLocation({
          variables: {
            input: {
              address: {
                addressLine2: '',
                addressLine1: device.homeownerAddress,
                city: device.homeownerCity,
                contactName: device.technicianName,
                countryCode: 'US',
                stateProvinceRegionCode: device.homeownerState,
                zipPostalCode: device.hhZip,
              },
              name: device.locationName,
              partnerAccountId,
              partnerCustomerId: partnerCustomer.id,
            },
          },
          onCompleted: ({ partnerDeviceLocationCreate }) => {
            if (partnerDeviceLocationCreate) {
              const partnerDeviceLocationId = partnerDeviceLocationCreate?.deviceLocation?.id;
              resolve({
                exists: false,
                locationId: partnerDeviceLocationId as string,
              });
            } else {
              reject(new Error('Partner device location creation failed.'));
            }
          },
          onError: error => {
            console.error(error.message || error);
            reject(error);
          },
        });
      }
    });
  };
  const getDeviceIdByMacId = (macId: string): Promise<LyricThermostatDevice> => {
    return new Promise((resolve, reject) => {
      getDeviceByMacId({
        variables: {
          deviceId: macId,
        },
        fetchPolicy: 'network-only',
        onCompleted: ({ search }) => {
          if (search) {
            resolve(search as LyricThermostatDevice);
          } else {
            reject(new Error('Failed to retrieve deviceId from macId'));
          }
        },
        onError: error => {
          console.error(error.message || error);
          reject(error);
        },
      });
    });
  };
  const processDevice = (
    deviceId: string,
    partnerDeviceLocationId: string
  ): Promise<{ exists: boolean; deviceServiceId?: string }> => {
    return new Promise((resolve, reject) => {
      // Get a list of devices already associated with the location
      getPartnerDeviceLocation({
        variables: {
          partnerDeviceLocationId,
        },
        fetchPolicy: 'network-only',
        onCompleted: ({ getPartnerDeviceLocation }) => {
          const locationDevices = getPartnerDeviceLocation?.devices?.edges
            .map(deviceEdge => deviceEdge?.node)
            .filter(device => device?.id === deviceId);

          if (locationDevices?.length) {
            resolve({
              exists: true,
            });
          } else {
            // install device
            installPartnerDevice({
              variables: {
                input: {
                  deviceId: deviceId,
                  installedAt: getCurrentFormattedTime(),
                  partnerDeviceLocationId,
                  status: 'ACTIVE',
                },
              },
              onCompleted: ({ installationPartnerDeviceServiceCreate }) => {
                if (installationPartnerDeviceServiceCreate) {
                  resolve({
                    exists: false,
                    deviceServiceId: installationPartnerDeviceServiceCreate.deviceService?.id as string,
                  });
                } else {
                  reject(new Error('A system error occurred while adding the device to a customer location'));
                }
              },
              onError: error => {
                console.error(error.message || error);
                reject(error);
              },
            });
          }
        },
        onError: error => {
          console.error(error.message || error);
          reject(error);
        },
      });
    });
  };

  const onComfortDeviceHandoffToConsumer = (
    installationPartnerDeviceServiceId: string,
    partnerCustomer: PartnerCustomer,
    macId: string
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      comfortDeviceHandoffToConsumer({
        variables: {
          input: {
            installationPartnerDeviceServiceId,
            claimStatus: 'PRO_CLAIM',
            deviceType: 'homes.d.t10', // hard coded for the moment.
            deviceVariant: 'homes.dv.t10na', // FlyCatcher_NA
            firstName: partnerCustomer.firstName,
            lastName: partnerCustomer.lastName,
            customerEmail: partnerCustomer.email,
            partnerDeviceId: macId,
            installedDate: new Date().toDateString(),
            companyName: partnerAccount?.name,
            companyEmail: partnerAccount?.contactEmail,
          },
        },
        onCompleted: ({ ComfortDeviceHandoffToConsumer }) => {
          if (ComfortDeviceHandoffToConsumer?.errors && ComfortDeviceHandoffToConsumer?.errors?.length > 0) {
            console.error('Error Creating Device Handoff:', ComfortDeviceHandoffToConsumer?.errors[0]?.message);
            reject(new Error(`Error Creating Device Handoff`));
          } else {
            resolve();
          }
        },
        onError: error => {
          console.error(error.message || error);
          reject(error);
        },
      });
    });
  };

  const processService = (
    deviceId: string,
    partnerDeviceLocationId: string
  ): Promise<{ exists: boolean; status?: string }> => {
    return new Promise((resolve, reject) => {
      getDeviceByNodeId({
        variables: {
          deviceNodeId: deviceId,
        },
        fetchPolicy: 'network-only',
        onCompleted: ({ getDeviceByNodeId }) => {
          const device = getDeviceByNodeId as LyricThermostatDevice;
          const deviceBrandingService = device.deviceServices?.edges.find(
            edge => edge?.node?.['__typename'] === DeviceServices.BrandingPartnerDeviceService
          );

          if (deviceBrandingService?.node?.deviceLocation?.id === partnerDeviceLocationId) {
            resolve({
              exists: true,
            });
          } else {
            // apply service
            addBrandingPartnerDeviceServiceInstallation({
              variables: {
                input: {
                  deviceId: deviceId,
                  installedAt: getCurrentFormattedTime(),
                  partnerDeviceLocationId,
                  status: 'ACTIVE',
                },
              },
              onCompleted: response => {
                const errors = response?.brandingPartnerDeviceServiceCreate?.errors;

                if (errors?.length) {
                  const message = errors[0]?.message;
                  if (message === 'Cannot have more than one active BrandingPartnerDeviceService for Device') {
                    // console.log(
                    //   `Cannot have more than one active BrandingPartnerDeviceService for Device(${deviceId})`
                    // );
                    resolve({
                      exists: true,
                    });
                  } else {
                    console.error(message);
                    reject(message);
                  }
                } else {
                  resolve({
                    exists: false,
                  });
                }
              },
              onError: e => {
                console.error(e);
                reject(e);
              },
            });
          }
        },
        onError: error => {
          console.error(error.message || error);
          reject(error);
        },
      });
    });
  };

  const initSyncResult = () => {
    setSyncStatuses([]);
    setTotalSyncStatus(initTotalSyncStatus);
    setSyncFailures([]);
  };

  const onSyncStatus = async () => {
    initSyncResult();

    // console.log(`Bulk Sync has started for ${devices.length} devices.`);

    for (let index = 0; index < devices.length; index++) {
      const device = devices[index];
      // console.log(`Synchronization of device #${device.id} has started.`, device);

      let deviceStatus = '';
      // Update state to show that processing for this device has started
      setSyncStatuses(prev => [
        ...prev,
        {
          id: device.id,
          customer: SYNC_STATUS.na,
          location: SYNC_STATUS.na,
          device: SYNC_STATUS.na,
          service: SYNC_STATUS.na,
          status: (SYNC_STATUS.processing as unknown) as Element,
        },
      ]);

      // Simulate or actually get the device status
      // console.log(`Get the device status from the GEE API.`);
      try {
        const deviceStatusRes = await getRegistrationStatus(device.hhLocationID, device.macID, device.hhUserID);
        if (deviceStatusRes.err) {
          // console.log('Skipped due to Invalid Device Status', deviceStatusRes);
          updateSyncFailures(index, device.id, 'Skipped due to Invalid Device Status', deviceStatusRes);

          deviceStatus = SYNC_STATUS.skipped;
        }
      } catch (error) {
        // console.log(`Exception when getting the device status`, error);
        updateSyncFailures(index, device.id, `Exception when getting the device status`, error);
        // console.log('Set device status to skipped');

        deviceStatus = SYNC_STATUS.skipped;
      }
      // Immediately reflect the skipped status or continue with processing
      if (deviceStatus === SYNC_STATUS.skipped) {
        updateSyncStatus(index, STATUS_TYPE.status, SYNC_STATUS.skipped);
        setTotalSyncStatus(prev => ({
          ...prev,
          skipCount: prev.skipCount + 1,
        }));
        // console.log(`Skip further processing for the device #${device.id}`);

        continue; // Skip further processing for this device
      }

      updateSyncStatus(index, STATUS_TYPE.status, SYNC_STATUS.blank);
      try {
        // Process Customer
        // console.log(`Customer Processing Started.`);
        updateSyncStatus(index, STATUS_TYPE.customer, SYNC_STATUS.processing);
        const customerResult = await processCustomer(device).catch(error => {
          // console.log(`Exception processing Customer for device ${device.id}`, error);
          updateSyncStatus(index, STATUS_TYPE.customer, SYNC_STATUS.error);
          updateSyncFailures(index, device.id, `Exception processing Customer for device ${device.id}`, error);
          throw error; // Rethrow to skip to the next device
        });
        // console.log(`Customer Processing Successful: Exist(${customerResult.exists})`, customerResult);
        updateSyncStatus(index, STATUS_TYPE.customer, customerResult.exists ? SYNC_STATUS.exists : SYNC_STATUS.created);

        const partnerCustomer = customerResult.data.partnerCustomer;

        // Process Location
        updateSyncStatus(index, STATUS_TYPE.location, SYNC_STATUS.processing);
        // console.log(`Location Processing Started.`);

        const locationResult = await processLocation(partnerCustomer, device).catch(error => {
          // console.log(`Exception during processing Location`, error);
          updateSyncStatus(index, STATUS_TYPE.location, SYNC_STATUS.error);
          updateSyncFailures(index, device.id, `Exception during processing Location`, error);
          throw error; // Rethrow to skip to the next device
        });
        // console.log(`Location Processing Successful: Exist(${locationResult.exists})`, locationResult);
        updateSyncStatus(index, STATUS_TYPE.location, locationResult.exists ? SYNC_STATUS.exists : SYNC_STATUS.created);

        const partnerDeviceLocationId = locationResult.locationId;

        // Process Device Step
        // console.log(`Device Processing Started.`);
        updateSyncStatus(index, STATUS_TYPE.device, SYNC_STATUS.processing);

        // Convert MAC ID to Device ID
        // console.log(`'Convert MAC ID to Device ID' Started`);
        const deviceInfo = await getDeviceIdByMacId(device.macID).catch(error => {
          // console.log(`Exception during getting real Device id by macId`, error);
          updateSyncStatus(index, STATUS_TYPE.device, SYNC_STATUS.error);
          updateSyncFailures(index, device.id, `Exception during getting real Device id by macId`, error);
          throw error; // Rethrow to skip to the next device
        });
        const deviceId = deviceInfo?.id;
        // console.log(`'Convert MAC ID to Device ID' Successful: Real DeviceID${deviceId}`);
        const deviceProcessResult = await processDevice(deviceId as string, partnerDeviceLocationId).catch(error => {
          // console.log(`Exception during processing Device`, error);
          updateSyncStatus(index, STATUS_TYPE.device, SYNC_STATUS.error);
          updateSyncFailures(index, device.id, `Exception during processing Device`, error);
          throw error; // Rethrow to skip to the next device
        });

        // console.log(`Device Processing Successful: Exist(${deviceProcessResult.exists})`, deviceProcessResult);
        if (deviceProcessResult.exists) {
          updateSyncStatus(index, STATUS_TYPE.device, SYNC_STATUS.exists);
          updateSyncStatus(index, STATUS_TYPE.status, SYNC_STATUS.skipped);
          setTotalSyncStatus(prev => ({
            ...prev,
            skipCount: prev.skipCount + 1,
          }));
          continue;
        } else {
          updateSyncStatus(index, STATUS_TYPE.device, SYNC_STATUS.added);
        }

        // Process Service Step
        // console.log(`Service Processing Started.`);
        updateSyncStatus(index, STATUS_TYPE.service, SYNC_STATUS.processing);

        const serviceProcessResult = await processService(deviceId as string, partnerDeviceLocationId).catch(error => {
          // console.log(`Exception during processing Service`, error);
          updateSyncStatus(index, STATUS_TYPE.service, SYNC_STATUS.error);
          updateSyncFailures(index, device.id, `Exception during processing Service`, error);
          throw error; // Rethrow to skip to the next device
        });

        // console.log(`Service Processing Successful: Exist(${serviceProcessResult.exists})`, serviceProcessResult);
        if (serviceProcessResult.exists) {
          updateSyncStatus(index, STATUS_TYPE.service, SYNC_STATUS.exists);
          updateSyncStatus(index, STATUS_TYPE.status, SYNC_STATUS.success);
        } else {
          await onComfortDeviceHandoffToConsumer(
            `${deviceProcessResult.deviceServiceId}`,
            partnerCustomer,
            device.macID
          ).catch(error => {
            // console.log(`Exception during 'Device Handoff/invitation'`, error);
            updateSyncStatus(index, STATUS_TYPE.service, SYNC_STATUS.error);
            updateSyncFailures(index, device.id, `Exception during 'Device Handoff/invitation'`, error);
            throw error; // Rethrow to skip to the next device
          });
          // console.log(`'Device Handoff/invitation' Processing Successful`);
          updateSyncStatus(index, STATUS_TYPE.service, SYNC_STATUS.applied);
          updateSyncStatus(index, STATUS_TYPE.status, SYNC_STATUS.success);
        }

        // If all operations succeed, update the overall status to success
        updateSyncStatus(index, STATUS_TYPE.status, SYNC_STATUS.success);
        setTotalSyncStatus(prev => ({
          ...prev,
          successCount: prev.successCount + 1,
        }));
      } catch (error) {
        updateSyncStatus(index, STATUS_TYPE.status, SYNC_STATUS.error);
        setTotalSyncStatus(prev => ({
          ...prev,
          errorCount: prev.errorCount + 1,
        }));
        continue; // Proceed to the next device
      }
    }
  };

  const updateSyncStatus = (index, type, status) => {
    setSyncStatuses(prevStatuses => {
      const newStatuses = [...prevStatuses];
      const currentStatus = newStatuses[index];
      if (currentStatus) {
        currentStatus[type] = status;
      }
      return newStatuses;
    });
  };
  const updateSyncFailures = (index: number, id: number, message: string, data) => {
    setSyncFailures(prev => {
      const newFailure = [...prev];
      newFailure[index] = {
        id,
        message,
        data,
      };

      return newFailure;
    });
  };
  return (
    <DashboardLayout>
      <DevicesFilter
        setDateRange={value => {
          getDevices(value);
          initSyncResult();
        }}
        resetData={() => {
          setDevices([]);
          initSyncResult();
        }}
      />

      <Content>
        <DevicesTable
          isLoading={loadingDevices}
          columns={DEVICE_COLUMNS}
          header={{
            sortParam: '',
          }}
          body={{
            data: devices,
          }}
        />

        <Button
          style={{ margin: '12px 25px', width: '150px' }}
          disabled={loadingDevices || !devices.length}
          onClick={onSyncStatus}>
          Go
        </Button>

        <SyncStatusTable
          columns={SYNC_STATUS_COLUMNS}
          header={{
            sortParam: '',
          }}
          body={{
            data: syncStatuses,
          }}
        />
      </Content>

      <SyncStatusTotalWrapper>
        <SyncStatusTotalTitle>Statistics:</SyncStatusTotalTitle>
        <SyncStatusTotalContent>
          <SyncStatusTotalValue>{totalSyncStatus.successCount}</SyncStatusTotalValue>
          <SyncStatusTotalLabel>Success</SyncStatusTotalLabel>
        </SyncStatusTotalContent>

        <SyncStatusTotalContent>
          <SyncStatusTotalValue>{totalSyncStatus.errorCount}</SyncStatusTotalValue>
          <SyncStatusTotalLabel>Error</SyncStatusTotalLabel>
        </SyncStatusTotalContent>

        <SyncStatusTotalContent>
          <SyncStatusTotalValue>{totalSyncStatus.skipCount}</SyncStatusTotalValue>
          <SyncStatusTotalLabel>Skipped</SyncStatusTotalLabel>
        </SyncStatusTotalContent>
      </SyncStatusTotalWrapper>

      {syncFailures.length > 0 && (
        <Content>
          <SyncFailureTable body={{ data: syncFailures }} />
        </Content>
      )}
    </DashboardLayout>
  );
};

export default ProPortalBulkSync;
