export function getWebsocketUrl(url: string) {
  const apiUrl = new URL(url);
  const { protocol, hostname, port } = apiUrl;
  const wsProtocol = protocol === 'http:' ? 'http:' : 'https:';
  return `${wsProtocol}//${hostname}${port && `:${port}`}`;
}

export type IOSocketMessageType = {
  username: string;
  type?: string;
  status?: string;
  data?: any;
};
