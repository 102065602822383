import React, { FC } from 'react';
import { Card, Heading, Text } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { CenterContent } from 'components/Layout';

const Container = styled(CenterContent)`
  margin-top: ${({ theme }) => theme.space.xxLarge};
  padding: ${({ theme }) => theme.space.large};
  max-width: ${({ theme }) => theme.breakpoints.small};
  background: ${({ theme }) => theme.colors.card};
  border-top: 5px solid ${({ theme }) => theme.colors.primary};
`;

const AccessDenied: FC = () => {
  return (
    <Container>
      <Card borderRadius={[0, 'medium']} padding='large' data-test-access-denied>
        <Heading as='h1' fontSize='large' marginBottom='medium'>
          Access Denied!
        </Heading>
        <Text>Your assigned role does not have access to this portal.</Text>
        <Text>Please contact support.</Text>
      </Card>
    </Container>
  );
};

export default AccessDenied;
