import React from 'react';
import Select from 'react-select';
import { components } from 'react-select';
import styled from 'styled-components';
import { SelectBoxContainer, SelectBoxWrapper } from './styles';
import {
  ACCOUNT_DELETED,
  DEVICE_DELETED,
  FAILED_CONNECTION,
  NOT_CONNECTED,
  OFFLINE,
  ONLINE,
  PENDING_PROVISION,
  PROVISION,
} from '../../constants/deviceStatus';

export const DevicesHeader = ({ filterByStatus, setStatus }) => {
  return (
    <div
      style={{
        display: 'flex',
        marginLeft: '25px',
      }}>
      <h2 style={{ color: '#4cb5f5' }}>Completed Devices</h2>
      <SelectBoxContainer>
        <label>Status</label>
        <SelectBoxWrapper>
          <Select
            isMulti
            value={filterByStatus}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Option,
            }}
            options={[
              {
                value: ONLINE,
                label: ONLINE,
              },
              {
                value: OFFLINE,
                label: OFFLINE,
              },
              {
                value: PENDING_PROVISION,
                label: PENDING_PROVISION,
              },
              {
                value: DEVICE_DELETED,
                label: DEVICE_DELETED,
              },
              {
                value: ACCOUNT_DELETED,
                label: ACCOUNT_DELETED,
              },
              {
                value: PROVISION,
                label: PROVISION,
              },
              {
                value: NOT_CONNECTED,
                label: NOT_CONNECTED,
              },
              {
                value: FAILED_CONNECTION,
                label: FAILED_CONNECTION,
              },
            ]}
            onChange={value => {
              setStatus(value);
            }}
            className='basic-multi-select'
            classNamePrefix='select'
          />
        </SelectBoxWrapper>
      </SelectBoxContainer>
    </div>
  );
};

const Option = props => {
  const OptionComp = styled(components.Option)`
    background: none !important;
    color: black !important;
  `;

  return (
    <div>
      <OptionComp {...props}>
        <input type='checkbox' checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
      </OptionComp>
    </div>
  );
};
