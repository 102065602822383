import { gql } from '@apollo/client';

const PartnerCustomerCreate = gql`
  mutation PartnerCustomerCreate($input: PartnerCustomerCreateInput!) {
    partnerCustomerCreate(input: $input) {
      __typename
      partnerCustomer {
        createdAt
        firstName
        lastName
        id
        email
        partnerAccount {
          id
        }
        deviceLocations {
          edges {
            node {
              ... on PartnerDeviceLocation {
                id
                address {
                  addressLine1
                  addressLine2
                  city
                  contactName
                  countryCode
                  mailingAddressName
                  stateProvinceRegionCode
                  zipPostalCode
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default PartnerCustomerCreate;
