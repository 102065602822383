import { gql } from '@apollo/client';

const InstallationPartnerDeviceServiceCreate = gql`
  mutation InstallationPartnerDeviceServiceCreate($input: InstallationPartnerDeviceServiceCreateInput!) {
    installationPartnerDeviceServiceCreate(input: $input) {
      deviceService {
        id
        status
        deviceLocation {
          id
          partnerAccount {
            id
          }
        }
      }
    }
  }
`;

export default InstallationPartnerDeviceServiceCreate;
