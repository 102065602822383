import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { InputField } from '@resideo/blueprint-formik';
import { Box, Flex } from '@resideo/blueprint-react';
import { CustomButton, CustomLabel } from './styles';
import { INIT_DATE_RANGE } from 'routes/PreRegistrations';

export const DateFilter = ({ setDateRange, clearFilters }) => {
  const INIT_IS_DATE = {
    startDate: !!INIT_DATE_RANGE?.startDate,
    endDate: !!INIT_DATE_RANGE?.endDate,
  };

  const [isDate, setIsDate] = useState<{ startDate: boolean; endDate: boolean }>(INIT_IS_DATE);

  return (
    <Formik
      onSubmit={values => {
        setDateRange({ ...values });
      }}
      onReset={() => {
        clearFilters();
      }}
      initialValues={{
        startDate: INIT_DATE_RANGE?.startDate ?? '',
        endDate: INIT_DATE_RANGE?.endDate ?? '',
      }}>
      {({ resetForm }) => (
        <Form noValidate>
          <Flex alignItems={'center'} flexDirection={['column', 'row']}>
            <CustomLabel>Time Period</CustomLabel>
            <Box marginRight={20} width={300}>
              <InputField
                type={isDate.startDate ? 'date' : 'text'}
                onFocus={() => {
                  setIsDate({ ...isDate, startDate: true });
                }}
                label=''
                name='startDate'
                placeholder='Start Date'
              />
            </Box>
            <Box width={300}>
              <InputField
                type={isDate.endDate ? 'date' : 'text'}
                onFocus={() => {
                  setIsDate({ ...isDate, endDate: true });
                }}
                label=''
                name='endDate'
                placeholder='End Date'
              />
            </Box>
            <CustomButton type='submit' style={{ marginLeft: '30px' }}>
              Search
            </CustomButton>
            <a
              style={{ color: 'white', textDecoration: 'underline', cursor: 'pointer', marginLeft: '20px' }}
              onClick={e => {
                e.stopPropagation();
                resetForm();
                setIsDate(INIT_IS_DATE);
              }}>
              Reset
            </a>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
