import React, { useState } from 'react';
import { Card } from '@resideo/blueprint-react';
import { Form, Formik } from 'formik';
import { InputField } from '@resideo/blueprint-formik';
import { Box, Flex } from '@resideo/blueprint-react';
import { CustomButton, CustomLabel } from './styles';
import { INIT_DATE_RANGE } from 'routes/ProPortalBulkSync';

export const DevicesFilter = ({ setDateRange, resetData }) => {
  const INIT_IS_DATE = {
    startDate: !!INIT_DATE_RANGE?.startDate,
    endDate: !!INIT_DATE_RANGE?.endDate,
  };

  const [isDate, setIsDate] = useState<{ startDate: boolean; endDate: boolean }>(INIT_IS_DATE);

  return (
    <Card backgroundColor='#086d8b' padding={['medium', 'large']}>
      <Formik
        onSubmit={values => {
          setDateRange({ ...values });
        }}
        initialValues={{
          startDate: INIT_DATE_RANGE?.startDate ?? '',
          endDate: INIT_DATE_RANGE?.endDate ?? '',
        }}>
        {({ resetForm }) => (
          <Form noValidate>
            <Flex alignItems={'center'} flexDirection={['column', 'row']}>
              <CustomLabel>Time Period</CustomLabel>
              <Box marginRight={20} width={300}>
                <InputField
                  type={isDate.startDate ? 'date' : 'text'}
                  onFocus={() => {
                    setIsDate({ ...isDate, startDate: true });
                  }}
                  label=''
                  name='startDate'
                  placeholder='Start Date'
                />
              </Box>
              <Box width={300}>
                <InputField
                  type={isDate.endDate ? 'date' : 'text'}
                  onFocus={() => {
                    setIsDate({ ...isDate, endDate: true });
                  }}
                  label=''
                  name='endDate'
                  placeholder='End Date'
                />
              </Box>
              <CustomButton type='submit'>Fetch</CustomButton>
              <a
                style={{ color: 'white', textDecoration: 'underline', cursor: 'pointer', marginLeft: '20px' }}
                onClick={e => {
                  e.stopPropagation();
                  resetForm();
                  setIsDate(INIT_IS_DATE);
                  resetData();
                }}>
                Reset
              </a>
            </Flex>
          </Form>
        )}
      </Formik>
    </Card>
  );
};
