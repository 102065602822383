import React from 'react';
import {
  ProgressTitle,
  ProgressRow,
  ProgressMessage,
  ProgressStatus,
  ProgressResult,
  ResultStatus,
  ResultMessage,
  ProCustomerContainer,
} from 'components/Registration/styles';
import { StatusIcon } from './StatusIcon';

export const ProPortalProgresses = ({ proCustomerResult, proCustomerProgress }) => {
  return (
    <ProCustomerContainer>
      <ProgressTitle style={{ marginBottom: '22px' }}>Pro Customer Record</ProgressTitle>
      {Object.keys(proCustomerProgress).map((step, index) => (
        <ProgressRow key={index}>
          <ProgressMessage>{proCustomerProgress[step].message}</ProgressMessage>
          <ProgressStatus>{<StatusIcon status={proCustomerProgress[step].status} />}</ProgressStatus>
        </ProgressRow>
      ))}

      <ProgressResult>
        <ResultStatus>{proCustomerResult.status}</ResultStatus>
        <ResultMessage>{proCustomerResult.message}</ResultMessage>
      </ProgressResult>
    </ProCustomerContainer>
  );
};
