import { gql } from '__generated__/gql';

const GetPartnerDeviceLocation = gql(`
  query PartnerDeviceLocation($partnerDeviceLocationId: ID!) {
    getPartnerDeviceLocation(id: $partnerDeviceLocationId) {
      devices {
        edges {
          node {
            deviceId
            id
          }
        }
      }
    }
  }
`);

export default GetPartnerDeviceLocation;
