import React, { Suspense, useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { InputField, PasswordField } from '@resideo/blueprint-formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import PageLoading from 'components/common/PageLoading';
import { useToast } from 'components/common/Toast';
import { ErrorAlert } from 'components/common/Alert';
import { StyledButton } from '../styles';
import { getDeviceByEmail } from 'api';
import { SearchMacID, isMacIDValidationEnabled } from '../SearchMacID';

export const StepTwo = ({ state, setStep, regDeviceState, setExistingAccount }) => {
  const [gdrResults, setGdrResults] = useState<any>(null);
  const { productId = '', id = '' } = gdrResults || {};
  const [showPassword, setShowPassword] = useState(false);
  const CustomField = styled(InputField)`
    width: 300px;
  `;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const { relatedDeviceWithST } = state;

  const lengthError = 'Thermostat Mac IDs must be exactly 12 characters long.';
  const genericLengthError = (lowerBound, upperBound) =>
    `Should be between ${lowerBound} and ${upperBound} characters long`;
  const requiredFieldError = 'The field is required.';

  const baseSchema = Yup.object().shape({
    macID: Yup.string()
      .matches(/^[A-Za-z0-9]*$/, 'Thermostat Mac IDs can only contain alphanumeric characters.')
      .min(12, lengthError)
      .max(12, lengthError)
      .required(requiredFieldError),
    locationName: Yup.string()
      .min(4, genericLengthError(4, 100))
      .max(100, genericLengthError(4, 100))
      .required(requiredFieldError),
    deviceName: Yup.string()
      .matches(/^[A-Za-z0-9]*$/, 'Device Name can only contain alphanumeric characters.')
      .min(4, genericLengthError(4, 100))
      .max(100, genericLengthError(4, 100))
      .required(requiredFieldError),
    firstName: Yup.string()
      .min(2, genericLengthError(2, 50))
      .max(50, genericLengthError(2, 50))
      .required(requiredFieldError),
    lastName: Yup.string()
      .min(2, genericLengthError(2, 50))
      .max(50, genericLengthError(2, 50))
      .required(requiredFieldError),
    email: Yup.string()
      .email()
      .required(requiredFieldError),
    zip: Yup.string()
      .matches(/^[0-9]*$/, 'Zipcode can only contain numeric characters')
      .min(5, 'Zipcode must contain exactly 5 characters')
      .max(5, '"Zipcode must contain exactly 5 characters')
      .required(requiredFieldError),
  });

  const passwordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Please Enter your password')
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
    repeatPassword: Yup.string()
      .required('Please Retype your password')
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], 'Passwords must be identical'),
      }),
  });

  const DetailsSchema = () => {
    if (!relatedDeviceWithST) {
      return baseSchema.concat(passwordSchema);
    }
    return baseSchema;
  };

  const CustomLoader = styled.div`
    margin-block-start: 20px;
    margin-inline-start: 10px;
  `;

  const handleSubmit = async (e: any) => {
    try {
      setLoading(true);
      let existingDevice = null;

      if (!relatedDeviceWithST) {
        existingDevice = await getDeviceByEmail(e.email);
      }
      setLoading(false);

      if (existingDevice) {
        const { hhUserID, hhLocationID } = existingDevice;
        setExistingAccount({
          isExisting: true,
          data: {
            hhUserID,
            hhLocationID,
          },
        });
      } else {
        if (relatedDeviceWithST) {
          const { hhUserID, hhLocationID } = relatedDeviceWithST;
          setExistingAccount({
            isExisting: true,
            data: {
              hhUserID,
              hhLocationID,
            },
          });
        } else {
          setExistingAccount({
            isExisting: false,
            data: null,
          });
        }
      }

      regDeviceState(prevState => {
        const newState = {
          ...prevState,
          ...e,
          productId: 'FlyCatcher', // TODO: should use the productId from GDR
          macID: e.macID.toUpperCase(),
          locationName: e.locationName,
          deviceName: e.deviceName,
          deviceId: id,
          // proPortalEnabled: true, // For only test
        };
        return newState;
      });
      setStep(3);
    } catch (err) {
      setLoading(false);
      toast(ErrorAlert(t, 'Something went wrong while checking existing account.'));
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            flex: 1,
            height: '230px',
          }}>
          <PageLoading />
        </div>
      ) : (
        <div>
          <Formik
            initialValues={{
              ...state,
              password: '',
              repeatPassword: '',
            }}
            validationSchema={DetailsSchema}
            onSubmit={handleSubmit}>
            {({
              handleSubmit,
              handleChange,
              values,
              values: { password, repeatPassword, macID, locationName, deviceName, firstName, lastName, email, zip },
            }) => (
              <Form
                role='form'
                data-test-sign-in-form
                noValidate
                onSubmit={e => {
                  e.preventDefault();
                  handleSubmit();
                }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                  }}>
                  <div
                    style={{
                      width: '360px',
                    }}>
                    <h3>Device Details</h3>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <CustomField
                        onChange={handleChange}
                        value={macID}
                        label='Thermostat MAC ID'
                        name='macID'
                        required
                      />
                      <Suspense
                        fallback={
                          <CustomLoader>
                            <PageLoading />
                          </CustomLoader>
                        }>
                        <SearchMacID gdrResults={gdrResults} setGdrResults={setGdrResults} macID={macID} />
                      </Suspense>
                    </div>
                    <CustomField
                      onChange={handleChange}
                      value={locationName}
                      label='Location Name'
                      defaultValue={'Home'}
                      name='locationName'
                      required
                      disabled={relatedDeviceWithST}
                    />
                    <CustomField
                      onChange={handleChange}
                      value={deviceName}
                      label='Device Name'
                      name='deviceName'
                      required
                    />
                  </div>
                  <div
                    style={{
                      width: '300px',
                    }}>
                    <h3>Customer Details</h3>
                    <CustomField
                      onChange={handleChange}
                      value={firstName}
                      label='First Name'
                      name='firstName'
                      required
                      disabled={relatedDeviceWithST}
                    />
                    <CustomField
                      onChange={handleChange}
                      value={lastName}
                      label='Last Name'
                      name='lastName'
                      required
                      disabled={relatedDeviceWithST}
                    />
                    <CustomField
                      onChange={handleChange}
                      value={email}
                      label='Email Address'
                      name='email'
                      required
                      disabled={relatedDeviceWithST}
                    />
                    <CustomField
                      onChange={handleChange}
                      value={zip}
                      label='Zip Code'
                      name='zip'
                      required
                      disabled={relatedDeviceWithST}
                    />
                    {!relatedDeviceWithST && (
                      <>
                        <PasswordField
                          label='Temporary Password'
                          name='password'
                          value={password}
                          hidePasswordLabel='password'
                          onToggle={() => {
                            setShowPassword(!showPassword);
                          }}
                          required
                          showPassword={showPassword}
                          showPasswordLabel={'Password'}
                          placeholder='Password'
                          onChange={handleChange}
                        />
                        <PasswordField
                          label='Confirm Temporary Password'
                          name='repeatPassword'
                          value={repeatPassword}
                          hidePasswordLabel='Retype Password'
                          onToggle={() => {
                            setShowPassword(!showPassword);
                          }}
                          required
                          showPassword={showPassword}
                          showPasswordLabel={'Retype Password'}
                          placeholder='Retype Password'
                          onChange={handleChange}
                        />
                      </>
                    )}
                    <ul
                      style={{
                        fontSize: '12px',
                        paddingLeft: '0',
                        width: '500px',
                        listStyle: 'none',
                      }}>
                      <li>
                        <strong>By clicking on Next you agree with the terms and conditions as set forth below:</strong>
                        <p>
                          You are subject to our{' '}
                          <a target='_blank' rel='noreferrer' href='https://pro.resideo.com/terms'>
                            Pro Portal Terms and Conditions
                          </a>{' '}
                          and all other relevant terms that apply to the use of our devices, services, apps and display
                          of brands. You will assign an appropriate unique temporary password for initial log-in and
                          provide it to the end user. You will inform the end user to change the assigned temporary
                          password after installation is complete. You will configure the user account per the
                          user&apos;s specifications and confirm its correctness. You will not, at any time, attempt to
                          use the temporary password to log into the end user&apos;s account. In the event, any end user
                          asks us to disassociate their device from you we will do so at our discretion.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                  }}>
                  <StyledButton
                    onClick={e => {
                      e.stopPropagation();
                      regDeviceState(prevState => ({
                        ...prevState,
                        ...values,
                      }));
                      setStep(1);
                    }}
                    type='button'
                    variant='secondary'>
                    Go Back
                  </StyledButton>
                  <StyledButton
                    style={{
                      marginLeft: '20px',
                    }}
                    disabled={isMacIDValidationEnabled ? !productId : false}
                    type='submit'
                    variant='primary'>
                    Next
                  </StyledButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
