export default {
  title: 'Resideo App',
  welcome: 'Welcome',
  common: {
    finish: 'Finish',
    cancel: 'Cancel',
    edit: 'Edit',
    submit: 'Submit',
    save: 'Save',
    reset: 'Reset',
    close: 'Close',
    saving: 'Saving...',
    back: 'Back',
    next: 'Next',
    step: 'Step',
    forms: {
      name: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      phone: 'Phone',
      primaryPhone: 'Primary Phone',
      secondaryPhone: 'Secondary Phone',
      address1: 'Address 1',
      address2: 'Address 2',
      city: 'City',
      state: 'State/Province',
      zip: 'Zip/Postal Code',
      country: 'Country',
      email: 'Email',
      role: 'Role',
      perksRole: 'Perks Role',
      choose: 'Choose one...',
      chooseMore: 'Choose one or more...',
    },
    validation: {
      requiredFields: '*Required Fields',
      required: 'This field is required',
      emailInvalid: 'Email address is invalid',
    },
    roles: {
      ADMIN: 'Admin',
      BILLING: 'Billing',
      COMPANY_ADMIN: 'Company Admin',
      MARKETING: 'Marketing',
      OFFICE_ADMIN: 'Office Admin',
      OPERATIONS: 'Operations',
      OWNER: 'Owner',
      SALES: 'Sales',
      TECHNICIAN_INSTALLER: 'Technician Installer',
      TECHNICIAN: 'Technician',
      MEMBER: 'Member',
    },
    toast: {
      error: 'Something went wrong!',
      success: 'Changes saved',
    },
    address: {
      address1: 'Address 1',
      address2: 'Address 2',
      city: 'City',
      state: 'State/Territory',
      province: 'Province',
      zip: 'Zip/Postal Code',
      country: 'Country/Region',
      addressRequired: 'Address is required',
      cityRequired: 'City is required',
      stateRequired: 'State is required',
      zipRequired: 'Zip/Postal code is required',
      zipInvalid: 'Invalid Zip/Postal Code',
      provinceRequired: 'Province is required',
      countryRequired: 'Country is required',
    },
    countries: {
      label: 'Country',
      US: 'United States',
      MX: 'Mexico',
      CA: 'Canada',
    },
  },
  header: {
    primary: {
      profile: 'Profile',
    },
    signout: 'Sign out',
    profile: 'My profile',
  },
  footer: {
    copyright:
      '\u00a9 {{year}} Resideo Technologies, Inc. The Honeywell Home trademark is used under license from Honeywell International Inc.',
    socialMediaHeader: 'Connect With Us',
    legal: {
      links: {
        resideo: 'Resideo.com',
        honeywellhome: 'HoneywellHome.com',
        privacypolicy: 'Privacy Policy',
        termsconditions: 'Terms & Conditions',
        sitemap: 'Sitemap',
      },
    },
  },
  users: {
    acceptTerms: 'Accept T&C Version',
    backButton: 'Back to users list',
    createdAt: 'Creation date',
    created: 'Created',
    details: 'User Details',
    email: 'Email',
    invalidEmail: 'Invalid email',
    employeeId: 'EmployeeId',
    expertise: 'Expertise',
    expertiseRequired: 'Expertise is required',
    firstName: 'First name',
    firstNameRequired: 'First name is required',
    lmsId: 'LmsId',
    jobRole: 'Job role',
    lastLogin: 'Last login',
    lastName: 'Last name',
    lastNameRequired: 'Last name is required',
    phoneType: 'Phone type',
    phoneTypes: {
      business: 'Business',
      home: 'Home',
      other: 'Other',
    },
    phoneRequired: 'Primary phone number is required',
    invalidPhoneNumber: 'Invalid phone number',
    invalidPhoneType: 'Invalid phone type',
    primaryPhone: 'Primary phone',
    role: 'Role',
    roleRequired: 'Role is required',
    secondaryPhone: 'Secondary phone',
    status: 'Status',
    terms: 'Terms',
    updated: 'Updated',
  },
  404: {
    header: '404',
    subtitle: 'Oh Snap! This Page Could Not Be Found',
    description:
      'Sorry but the page you are looking for does not exist, have been removed, name changed or is temporarily unavailable',
  },
  pageError: {
    heading: "This page isn't working.",
    content: 'Try again, and contact us if the problem continues.',
  },
};
