// !Important: Deprecated Component
import React from 'react';
import styled from 'styled-components';

export const Header = styled.h2`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 180px;
  margin-bottom: 40px;
  font-weight: 400;
`;

export const TextCenter = styled.div`
  text-align: center;
  color: #00a9e0;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  color: #00a9e0;
  cursor: pointer;
`;

export const StepFour = ({ resetForm, error }) => {
  const StepErrors = () => (
    <p
      style={{
        textAlign: 'center',
        margin: 'auto',
        marginTop: '20px',
        width: '500px',
        color: '#f76b2a',
      }}>
      {error}
    </p>
  );

  return (
    <>
      <div>
        <Header>Thermostat successfully registered!</Header>
        <TextCenter />
      </div>
      <TextContainer onClick={() => resetForm()}>
        <div>Pre-register another device</div>
      </TextContainer>
      {error && <StepErrors />}
    </>
  );
};
