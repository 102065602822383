import React from 'react';
import { TableBody, TableHead } from './Table';
import { Table, TableContainer, TableScrollView } from './Table/styles';
import { TableTitle } from './styles';

export const SyncFailureTable = ({ body: { data } }) => {
  const columns = [
    {
      label: 'DB ID',
      key: 'id',
      width: '20px',
    },
    {
      label: 'Failure Reason',
      key: 'message',
    },
    {
      label: 'Detail',
      key: 'data',
      type: 'object',
    },
  ];
  return (
    <TableContainer>
      <TableTitle>Sync Failures</TableTitle>
      <TableScrollView>
        <Table cellPadding='0' cellSpacing='0'>
          <TableHead columns={columns} sortParam={''} />
          <TableBody records={data} columns={columns} />
        </Table>
      </TableScrollView>
    </TableContainer>
  );
};
