import React, { useEffect } from 'react';
import { io } from 'socket.io-client';
import { useWebSocketData } from 'components/WebSocketContext';
import { apiConfig } from 'config';
import { getWebsocketUrl } from 'utils/websockets';

const ManageWebSocket = () => {
  const { setWebSocketData } = useWebSocketData();

  useEffect(() => {
    const wsUrl = getWebsocketUrl(apiConfig.apiHost as string);
    console.log(`⏩ WebSocket is trying to connect!`);

    const newSocket = io(`${wsUrl}`, {
      transports: ['websocket'],
    });

    newSocket.on('connect', () => {
      setWebSocketData(prevState => ({
        ...prevState,
        isConnected: true,
      }));
      console.log('✅ WebSocket connected!');
    });

    newSocket.on('disconnect', () => {
      setWebSocketData(prevState => ({
        ...prevState,
        isConnected: false,
      }));
      console.log('🚫 Disconnected from server');
    });

    setWebSocketData(prevState => ({
      ...prevState,
      WebSocketClient: newSocket,
    }));

    return () => {
      console.log('🧽️ Current WebSocket cleaned up!');
      newSocket.disconnect();
    };
  }, []);

  return <></>;
};

export default ManageWebSocket;
