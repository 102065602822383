import React, { ReactElement, useState } from 'react';
import moment from 'moment-timezone';
import { RegistrationData } from 'components/PreRegistration/interfaces';
import { NoRecords, TableRow } from './styles';
import { ExpandablePre } from './ExpandablePre';

export const TableBody = ({ records, columns }: any) => {
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

  const toggleExpanded = (key: string) => {
    setExpandedRows(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const renderCell = (type: string, data: any, key: string | number, width?: string | number): ReactElement => {
    let content: ReactElement | string = '';

    switch (type) {
      case 'date':
        content = `${moment(data).format('MM/DD/YYYY')} ${moment(data).format('hh:mm A')}`;
        break;

      case 'object': {
        const expanded = expandedRows[key as string] || false;
        content = (
          <ExpandablePre data={data} expanded={expanded} toggleExpanded={() => toggleExpanded(key as string)} />
        );
        break;
      }

      default:
        content = data;
        break;
    }

    return (
      <td key={key} style={width ? { width } : {}}>
        {content}
      </td>
    );
  };

  return (
    <tbody>
      {records.length > 0 ? (
        records.map((item: RegistrationData, index, array) => (
          <TableRow lastItem={array.length - 1 === index} key={item?.id}>
            {columns.map((column, colIndex) =>
              renderCell(column.type, item?.[column.key], `${item?.id}-${colIndex}`, column.width)
            )}
          </TableRow>
        ))
      ) : (
        <tr>
          <td colSpan={7} style={{ borderBottom: 'none' }}>
            <NoRecords>No records found</NoRecords>
          </td>
        </tr>
      )}
    </tbody>
  );
};
