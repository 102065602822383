import { gql } from '@apollo/client';

const BrandingPartnerDeviceServiceCreate = gql(`
  mutation BrandingPartnerDeviceServiceCreate(
    $input: BrandingPartnerDeviceServiceCreateInput!
  ) {
    brandingPartnerDeviceServiceCreate(input: $input) {
      errors {
        message
        field
      }
      deviceService {
        id
        __typename
        ... on BrandingPartnerDeviceService {
          createdAt
          updatedAt
          status
        }
      }
    }
  }
`);

export default BrandingPartnerDeviceServiceCreate;
