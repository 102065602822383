import React from 'react';
import { ModalProps } from '@resideo/blueprint-react';
import {
  CreateNewLocation,
  ExistingLocation,
  ModalBody,
  ModalTitle,
  OrSeparator,
  RadioButtonLabel,
  RadioGroup,
  StyledButton,
} from './styles';
import { PartnerDeviceLocation } from './interfaces';

interface ChooseLocationModalProps {
  Modal: ({ children, ...rest }: ModalProps) => JSX.Element;
  openModal: () => void;
  closeModal: () => void;
  homeowner: string;
  continueWithSelectedLocation: (event: any) => void;
  existingLocations: PartnerDeviceLocation[] | undefined;
  selectedLocationId: string | undefined;
  handleAddressChange: (event: any) => void;
  handleCreateNewLocation: () => void;
}

export const ChooseLocationModal = ({
  Modal,
  homeowner,
  continueWithSelectedLocation,
  existingLocations,
  selectedLocationId,
  handleAddressChange,
  handleCreateNewLocation,
}: ChooseLocationModalProps) => {
  return (
    <Modal>
      <ModalTitle>
        <p style={{ width: '60%' }}>Could not find a location match for:</p>
        <p style={{ width: '40%' }}>{homeowner}</p>
      </ModalTitle>
      <form onSubmit={continueWithSelectedLocation}>
        <ModalBody>
          <ExistingLocation>
            <p>Select an existing location</p>
            <RadioGroup>
              {existingLocations?.map((location, index) => (
                <RadioButtonLabel key={index}>
                  <input
                    type='radio'
                    value={location.id}
                    checked={selectedLocationId === location.id}
                    onChange={handleAddressChange}
                  />
                  {location.address.mailingAddressName ||
                    `${location.address.addressLine1 || location.address.addressLine2} (${
                      location.address.stateProvinceRegionCode
                    } ${location.address.zipPostalCode})`}
                </RadioButtonLabel>
              ))}
            </RadioGroup>
          </ExistingLocation>
          <OrSeparator>-- OR --</OrSeparator>
          <CreateNewLocation>
            <p>Create a new location using the above address</p>
            <StyledButton onClick={handleCreateNewLocation} type='button' variant='secondary'>
              Create new
            </StyledButton>
          </CreateNewLocation>
        </ModalBody>
        <StyledButton style={{ marginLeft: '76px', marginTop: '20px' }} type='submit' variant='primary'>
          Continue
        </StyledButton>
      </form>
    </Modal>
  );
};
