import Pagination from 'components/Pagination/Pagination';
import { TableBody, TableHead } from 'components/Table';
import { Table, TableContainer } from 'components/Table/styles';
import PageLoading from 'components/common/PageLoading';
import React from 'react';

const PAGE_SIZE = 10;

export const DevicesTable = ({
  isLoading,
  columns,
  header: { isResideoAdmin, isTest, sortParam },
  body: { loadingStatus, data },
  page: { currentPage, setCurrentPage },
}) => {
  const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
  const lastPageIndex = firstPageIndex + PAGE_SIZE;

  return (
    <TableContainer>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            flexDirection: 'column',
            height: '80vh',
          }}>
          <PageLoading />
        </div>
      ) : (
        <>
          <Table cellPadding='0' cellSpacing='0'>
            <TableHead isResideoAdmin={isResideoAdmin} columns={columns} isTest={isTest} sortParam={sortParam} />
            <TableBody
              loadingStatus={loadingStatus}
              isResideoAdmin={isResideoAdmin}
              records={data.slice(firstPageIndex, lastPageIndex)}
              isTest={isTest}
              columns={columns}
            />
          </Table>
          <div
            style={{
              paddingBottom: '30px',
            }}>
            <Pagination
              className='pagination-bar'
              currentPage={currentPage}
              totalCount={data.length}
              pageSize={PAGE_SIZE}
              onPageChange={page => {
                setCurrentPage(page);
              }}
            />
          </div>
        </>
      )}
    </TableContainer>
  );
};
