import { gql } from '@apollo/client';

const PartnerDeviceLocationCreate = gql`
  mutation PartnerDeviceLocationCreate($input: PartnerDeviceLocationCreateInput!) {
    partnerDeviceLocationCreate(input: $input) {
      __typename
      deviceLocation {
        id
        createdAt
      }
    }
  }
`;

export default PartnerDeviceLocationCreate;
