import { gql } from '__generated__/gql';

const GetPartnerCustomers = gql(`
  query PartnerCustomers($partnerAccountNodeId: String, $filterBy: [PartnerCustomerFilterBy!]) {
    partnerCustomers(partnerAccountNodeId: $partnerAccountNodeId, filterBy: $filterBy) {
      edges {
        node {
          firstName
          id
          email
          lastName
          address {
            addressLine1
            addressLine2
            city
            contactName
            countryCode
            mailingAddressName
            stateProvinceRegionCode
            zipPostalCode
          }
          createdAt
          deletedAt
          phoneNumber
          updatedAt
          deviceLocations {
            edges {
              node {
                ... on PartnerDeviceLocation {
                  id
                  address {
                    addressLine1
                    addressLine2
                    city
                    contactName
                    countryCode
                    mailingAddressName
                    stateProvinceRegionCode
                    zipPostalCode
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

export default GetPartnerCustomers;
