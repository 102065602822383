import React from 'react';
import moment from 'moment-timezone';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { RegistrationData } from 'components/PreRegistration/interfaces';
import { NoRecords, ProCustomerDetail, TableRow } from './styles';
import PageLoading from 'components/common/PageLoading';
import { StatusCell } from 'components/PreRegistration';
import { getStatusAndText } from '../../utils/status';

export const TableBody = ({ records, isTest, isResideoAdmin, loadingStatus }: any) => {
  return (
    <tbody>
      {records.length > 0 ? (
        records.map((item: RegistrationData, index, array) => (
          <TableRow lastItem={array.length - 1 === index} key={item.id}>
            <td>
              {moment(item?.createdAt).format('MM/DD/YYYY')} &nbsp; {moment(item?.createdAt).format('hh:mm A')}
            </td>
            <td>{item?.serviceTitanJobID}</td>
            <td>
              {item?.homeownerFullName} <br /> {item?.homeownerAddress}
              <br />
              {item?.homeownerCity}, {item?.homeownerState} {item?.homeownerZip} <br />
              {item?.partnerCustomerAccountId && (
                <ProCustomerDetail>
                  <a
                    href={`${process.env.REACT_APP_PRO_PORTAL_URL}/mybusiness/customers/account-lookup/${item?.partnerCustomerAccountId}`}
                    target='_blank'
                    rel='noreferrer'>
                    View customer details
                  </a>
                  <FaExternalLinkAlt />
                </ProCustomerDetail>
              )}
            </td>
            <td>
              {item?.hhFirstName + ' ' + item?.hhLastName} <br />
              {item?.hhEmail}
              <br />
              {item?.hhZip}
            </td>
            <td>{item.macID}</td>
            <td>{item?.technicianName}</td>
            <td
              style={{
                width: '200px',
              }}>
              {loadingStatus ? <PageLoading /> : <StatusCell status={getStatusAndText(item.status)} />}
            </td>
            {isResideoAdmin && <td>{item.company?.companyName}</td>}
            {isTest && (
              <td>
                <p>User ID: {item.hhUserID}</p>
                <p>Location ID: {item.hhLocationID} </p>
              </td>
            )}
          </TableRow>
        ))
      ) : (
        <tr>
          <td colSpan={7} style={{ borderBottom: 'none' }}>
            <NoRecords>No records found</NoRecords>
          </td>
        </tr>
      )}
    </tbody>
  );
};
