import React from 'react';
import { Status } from '../interfaces';
import { MdPending, MdOutlineError, MdDoDisturbOn } from 'react-icons/md';
import { GiCheckMark } from 'react-icons/gi';
import { Spinner } from '@resideo/blueprint-react';

export const StatusIcon = ({ status }) => {
  switch (status) {
    case Status.Pending:
      return <MdPending color='#cccccc' />;
    case Status.Processing:
      return <Spinner color='primary' />;
    case Status.Failed:
      return <MdOutlineError color='#e30a0a' />;
    case Status.Cancelled:
      return <MdDoDisturbOn color='#607d8b' />;
    case Status.Success:
      return <GiCheckMark color='#00a9e0' />;
    default:
      return <></>;
  }
};
