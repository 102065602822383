import React from 'react';
import { ReactComponent as CaretUpComponent } from '../../images/caret-up.svg';
import { ReactComponent as CaretDownComponent } from '../../images/caret-down.svg';

export const TableHead = ({ columns, isTest, isResideoAdmin, sortParam }: any) => {
  return (
    <thead>
      <tr>
        {columns.map((node: any) => (
          <HeaderCell key={node.label} {...node} colKey={node.key} sortParam={sortParam} />
        ))}
        {isResideoAdmin && <th>Company</th>}
        {isTest && <th>More Info</th>}
      </tr>
    </thead>
  );
};

const HeaderCell = ({
  label,
  onClickHandler,
  caretState,
  colKey,
  sortParam,
}: {
  label: string;
  onClickHandler?: (e?: any) => void;
  caretState: boolean;
  sortParam: string;
  colKey: string;
}) => {
  return (
    <th onClick={() => onClickHandler && onClickHandler()}>
      {label}
      {typeof caretState !== 'undefined' &&
        (caretState ? (
          <CaretUpComponent style={{ height: '15px', fill: sortParam === colKey ? 'black' : 'grey', width: '20px' }} />
        ) : (
          <CaretDownComponent
            style={{ height: '15px', fill: sortParam === colKey ? 'black' : 'grey', width: '20px' }}
          />
        ))}
    </th>
  );
};
