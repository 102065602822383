import React, { createContext, useContext, useState } from 'react';

const Context = createContext({} as any);

export const useCustomAuthData = () => {
  const data = useContext(Context);
  return data;
};

export const AuthContext = ({ children }) => {
  const [authData, setAuthData] = useState({
    isResideoAdmin: false,
    hasPrivilege: false,
  });

  return (
    <Context.Provider
      value={{
        ...authData,
        setAuthData,
      }}>
      {children}
    </Context.Provider>
  );
};
