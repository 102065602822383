import moment from 'moment-timezone';

export function formatDate(date: number) {
  return new Date(date).toISOString().substring(0, 10);
}

export function getIncrementedDate(daysToIncrement: number, date?: string) {
  if (!date) return '';
  const dateObj = new Date(date);
  const incremented = dateObj.setDate(dateObj.getDate() + daysToIncrement);
  return formatDate(incremented);
}

export function getDecrementedDate(daysToDecrement: number, date?: string) {
  if (!date) return '';
  const dateObj = new Date(date);
  const decremented = dateObj.setDate(dateObj.getDate() - daysToDecrement);
  return formatDate(decremented);
}

//extracts date from ISO in a specific format
export function dateFormatter(isoDate: any) {
  const date = new Date(isoDate);
  return new Intl.DateTimeFormat('en-US').format(date);
}

//extracts time from ISO in a specific format
export function timeFormatter(isoDate: any) {
  if (!isoDate) return '';
  return new Intl.DateTimeFormat('en-US', {
    hour12: true,
    timeStyle: 'short',
  } as any)
    .format(new Date(isoDate))
    .replace(',', '');
}

export const isoDateToMilliseconds = (date: string) => {
  const newDate = new Date(date); // some mock date
  return newDate.getTime();
};

export const toUTCTime = (date: string): string => {
  if (!date) return '';

  const localTimeZone = moment.tz.guess(); // This will guess the system's local timezone

  const timeInUTC = moment
    .tz(date, localTimeZone)
    .utc()
    .format();

  return timeInUTC;
};

export const compareDate = (startDate: string, endDate: string): boolean => {
  if ((!startDate && endDate) || (!startDate && !endDate)) {
    return false;
  }

  if (startDate && !endDate) {
    return true;
  }

  const startAt = new Date(startDate);
  const endAt = new Date(endDate);

  if (startAt > endAt) {
    return false;
  } else {
    return true;
  }
};

export function getCurrentFormattedTime() {
  return moment().format('YYYY-MM-DDTHH:mm:ss');
}

export function getEndOfDayTimestamp(dateStr: string) {
  // Parse the date string into a Date object
  const date = new Date(dateStr);

  // Set time to the end of the day
  date.setHours(23, 59, 59, 999);

  // Return the timestamp
  return new Date(date.getTime()).toISOString();
}
