import styled from 'styled-components';
import { Button } from '@resideo/blueprint-react';
import { Form } from 'formik';

export const RegistrationWrapper = styled.div`
  width: 80%;
  margin: 3rem auto;
`;

export const StepWrapper = styled.div`
  margin-top: 30px;
`;

export const StyledButton = styled(Button)`
  border-radius: 2rem;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  min-width: 100px;
`;

export const NavigationButton = styled(StyledButton)`
  text-wrap: no-wrap;
  background-color: rgb(22, 106, 150);
  &:hover {
    background-color: #153755;
  }
`;

export const CustomForm = styled(Form)`
  width: 325px;
  display: grid;
  grid-auto-flow: column;
  justify-items: start;
  align-items: end;
  input {
    max-width: 193px;
  }
  input + div {
    max-width: 190px;
    margin: 0;
    margin-left: 2px;
    line-height: 1.3;
  }
`;

export const ModalTitle = styled.div`
  display: flex;
  width: 70%;
  margin: 0 auto;
`;

export const ModalBody = styled.div`
  display: flex;
`;

export const ExistingLocation = styled.div`
  margin-bottom: 20px;
`;

export const RadioGroup = styled.div`
  margin-left: 16px;
  max-height: 120px;
  overflow: hidden;
  overflow-y: auto;
`;

export const RadioButtonLabel = styled.label`
  display: flex;
  margin: 5px 0px;
  & > input {
    margin-right: 10px;
  }
`;

export const CreateNewLocation = styled.div`
  margin-bottom: 20px;
  width: 265px;
`;

export const OrSeparator = styled.div`
  position: relative;
  margin-top: 90px;
  margin-bottom: 70px;
  margin-left: 25px;
  margin-right: 25px;
  text-align: center;
  width: 56px;
  height: 24px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    width: 2px;
    height: 70px;
    background-color: #166a96;
    transform: translateX(-50%);
  }

  &:before {
    top: -70px;
  }

  &:after {
    bottom: -70px;
  }
`;

export const ProgressesContainer = styled.div`
  display: flex;
  margin: 40px auto 0;
  justify-content: center;
  align-items: flex-start;
`;

export const HomeownerContainer = styled.div`
  display: block;
  max-width: 315px;
  min-width: 300px;
  min-height: 315px;
`;

export const ProCustomerContainer = styled.div`
  max-width: 315px;
  min-width: 300px;
  margin-left: 40px;
  min-height: 315px;
`;

export const ProgressTitle = styled.h4`
  max-width: 300px;
  margin: 0;
  margin-bottom: 4px;
`;

export const ProgressRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px #d1d1d1;
  border-bottom: none;
  font-size: 14px;
`;
export const ProgressMessage = styled.div`
  padding: 14px 8px;
  width: calc(100% - 30px);
`;

export const ProgressStatus = styled.div`
  width: 50px;
  height: 47px;
  text-align: center;
  border-left: solid 1px #d1d1d1;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProgressResult = styled.div`
  border: solid 1px #d1d1d1;
  font-size: 14px;
  padding: 0 8px;
`;

export const ResultStatus = styled.h4`
  text-align: center;
  margin: 14px 0;
`;

export const ResultMessage = styled.div`
  margin: 0px 0 8px;
  font-size: 13px;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  max-width: 630px;
  justify-content: space-between;
  margin: 50px auto 0;
`;

export const ButtonContainer = styled.div`
  color: #00a9e0;
  cursor: pointer;
  margin-top: 16px;
  text-decoration: underline;
  & > a {
    color: #00a9e0;
  }
`;

export const DetailContainer = styled.div`
  & > div > p {
    display: flex;
    font-weight: bold;
    color: #303030;
    padding: 4px;
    & span {
      width: 150px;
      color: #757575;
      font-weight: bold;
    }
  }
`;
