import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 260px;
  background-color: #ededed;
`;

export const SideMenuHeader = styled.div`
  padding: 12px 0;
  padding-left: 20px;
  margin-bottom: 10px;
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.8px;
  line-height: 36px;
`;

export const LinkWrapper = styled.div`
  a {
    color: black;
    display: block;
    padding: 20px 0 20px 24px;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.2px;
  }
`;

export const CustomLink = styled(Link)<{
  isActive: boolean;
  disabled?: boolean;
}>`
  text-decoration: none;
  ${({ isActive }) => (isActive ? 'background-color: rgb(209, 209, 209);' : 'transparent;')}
  ${({ isActive }) => (isActive ? 'font-weight: 800 !important;' : 'font-weight: 400;')}
  ${({ disabled }) => disabled && 'color: grey !important;'}
  ${({ disabled }) => disabled && 'cursor: default;'}
  & > a {
    font-weight: ${({ isActive }) => (isActive ? 'normal' : '700')};
  }

  & > a:hover {
    text-decoration: none;
  }
`;
