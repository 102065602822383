import React from 'react';
import cn from 'classnames';
import Check from '../../images/check-solid.svg';
import styled from 'styled-components';

type Props = {
  step: number;
};

const Wrapper = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  z-index: 0;
  position: relative;
`;

const StepperItem = styled.div`
  & {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  &::before {
    position: absolute;
    content: '';
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
  }

  &::after {
    position: absolute;
    content: '';
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
  }

  & .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
  }

  & .step-counter.active {
    background: #03293b;
  }

  &.active {
    font-weight: bold;
  }

  &.completed .step-counter {
    background-color: #00a9e0;
  }

  &.completed::after {
    position: absolute;
    content: '';
    border-bottom: 2px solid #00a9e0;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
  }

  &:first-child::before {
    content: none;
  }
  &:last-child::after {
    content: none;
  }
`;

const Stepper = ({ step }: Props) => {
  const steps = [
    { number: 1, title: 'Customer Lookup' },
    { number: 2, title: 'Device Details' },
    { number: 3, title: 'Confirm & Submit' },
  ];

  const stepNumber = (num: number) => {
    const checkStep = step > num || step === 4;
    return step === num && step !== 4 ? 'active' : checkStep ? 'completed' : '';
  };

  const stepIcon = (num: number) => {
    return num >= step ? (
      <h6 style={{ color: 'white' }}>{num}</h6>
    ) : (
      <img width='20' height='20' src={Check} alt='icon-check' />
    );
  };

  return (
    <>
      <h2 style={{ color: '#00a9e0', textAlign: 'center' }}>Pre-Register a New Device</h2>
      <Wrapper>
        {steps.map((v, i) => (
          <StepperItem key={i} className={cn(stepNumber(v.number))}>
            <div className={cn('step-counter', stepNumber(v.number))}>{stepIcon(v.number)}</div>
            {step && <div className={'step-name'}>{v.title}</div>}
          </StepperItem>
        ))}
      </Wrapper>
    </>
  );
};

export default Stepper;
