import React, { useEffect } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { useDebounceQuery } from 'hooks/useDebounceQuery';
import light from 'themes/light';
import { FiCheck, FiX } from 'react-icons/fi';

export const isMacIDValidationEnabled =
  process.env.REACT_APP_API_HOST === 'https://pre-registration-backend.prod.titans.cloud';

export const SearchMacID = ({ macID = '', setGdrResults, gdrResults }) => {
  const deviceData = useDebounceQuery(macID, 500);

  useEffect(() => {
    if (!isEqual(gdrResults, deviceData)) {
      setGdrResults(deviceData);
    }
  }, [deviceData]);

  if (!macID) {
    return null;
  }

  return isMacIDValidationEnabled ? (
    <div
      style={{
        paddingBlockStart: '20px',
        marginInlineStart: '10px',
      }}>
      {isEmpty(deviceData) ? (
        <FiX stroke={light.colors.danger} size={20} style={{ marginRight: 8, marginBottom: -4 }} />
      ) : (
        <FiCheck stroke={light.colors.success} size={24} style={{ marginRight: 8, marginBottom: -4 }} />
      )}
    </div>
  ) : (
    <></>
  );
};
