import React, { FC, useEffect, Suspense } from 'react';
import { RedirectLoginOptions } from '@auth0/auth0-spa-js';
import { Route as ReactRouterRoute, RouteProps } from 'react-router-dom';
import { useAuth } from 'utils/auth';
import * as Sentry from '@sentry/react';
import PageError from 'components/common/PageError';
import PageLoading from 'components/common/PageLoading';
import config from 'config';
import { AppGuard } from './Guard';
import { AxiosInterceptor } from './AxiosInterceptor';

const { auth0Connection } = config;

export const AnonymousRoute: FC<RouteProps> = props => (
  <Sentry.ErrorBoundary fallback={<PageError />}>
    <Suspense fallback={<PageLoading />}>
      <ReactRouterRoute {...props} />
    </Suspense>
  </Sentry.ErrorBoundary>
);

const defaultReturnTo = (): string => `${window.location.pathname}${window.location.search}`;

const Route: FC<RouteProps> = props => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth();

  const returnTo = defaultReturnTo;
  const loginOptions: RedirectLoginOptions = {
    connection: auth0Connection,
  };

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const opts = {
      ...loginOptions,
      appState: {
        ...loginOptions.appState,
        returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
      },
    };
    (async (): Promise<void> => {
      await loginWithRedirect(opts);
    })();
  }, [isLoading, isAuthenticated, loginWithRedirect, loginOptions, returnTo]);

  const { path } = props;
  return (
    isAuthenticated && (
      <AppGuard path={path}>
        <AxiosInterceptor>
          <AnonymousRoute {...props} />
        </AxiosInterceptor>
      </AppGuard>
    )
  );
};

export default Route;
