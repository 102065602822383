import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  height: 100vh;
`;

export const ChildContainer = styled.div`
  width: 100%;
  padding-bottom: 5rem;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  margin-bottom: 60px;
  background: white;
  overflow: hidden;
  overflow-y: scroll;
`;
