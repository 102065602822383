import { Card } from '@resideo/blueprint-react';
import React from 'react';
import { Search } from './Search';
import { DateFilter } from './DateFilter';

export const DevicesFilter = ({
  filterByString: { setValue },
  filterByDate: { setDateRange, clearFilters },
  setStatus,
}) => {
  return (
    <Card backgroundColor='#086d8b' padding={['medium', 'large']}>
      <Search setValue={setValue} setStatus={setStatus} />
      <DateFilter setDateRange={setDateRange} clearFilters={clearFilters} />
    </Card>
  );
};
