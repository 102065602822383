import React from 'react';
import {
  ACCOUNT_DELETED,
  DEVICE_DELETED,
  FAILED_CONNECTION,
  NOT_CONNECTED,
  OFFLINE,
  ONLINE,
  PENDING_PROVISION,
  PROVISION,
} from '../constants/deviceStatus';

type StatusType = {
  isAlive: boolean;
  dataSyncStatus: string;
  status: number;
  err: {
    code: string;
  };
};

type DeviceStatus = {
  status: string;
  tooltip: JSX.Element;
  color?: string;
};

const NotConnectedTooltip = () => <p>Device has never been connected to the Internet.</p>;
const OnlineTooltip = () => <p>Device is currently online</p>;
const OfflineTooltip = () => (
  <>
    <p>Loss of connection may be temporary.</p>
    <p>Issues commonly include router connection, Internet strength, and power outages.</p>
    <p>For further assistance, please contact Resideo Customer Care at 1-800-468-1502.</p>
  </>
);
const FailedConnectionTooltip = () => (
  <>
    <p>Device failed to secure a connection.</p>
    <p>Please contact Resideo Customer Care at 1-800-468-1502.</p>
    <p>Have your customer email or device MAC ID on hand to share with our team.</p>
  </>
);

const PendingProvisionTooltip = () => (
  <>
    <p>Device has been registered by not yet connected to the Internet.</p>
  </>
);

const ProvisionTooltip = () => (
  <>
    <p>Device is making a first-time connection to the Internet, which only last for a few minutes.</p>
  </>
);

const AccountDeletedTooltip = () => (
  <>
    <p>Homeowner’s account has been deleted along with any registered devices.</p>
  </>
);

const DeviceDeletedTooltip = () => (
  <>
    <p>Device has been removed from homeowner’s account.</p>
  </>
);

const PENDING_COLOR = '#fcc669';
const ONLINE_COLOR = '#258039';
const OFFLINE_COLOR = '#dadada';
const FAILED_CONNECTION_COLOR = '#e41b1e';

export const getStatusAndText = (status: StatusType): DeviceStatus => {
  const {
    isAlive, // isOnline
    dataSyncStatus, // Data sync status
  } = status;

  // Online
  if (isAlive && dataSyncStatus === 'Completed') {
    return {
      status: ONLINE,
      color: ONLINE_COLOR,
      tooltip: <OnlineTooltip />,
    };
  }

  // Offline
  if (!isAlive && dataSyncStatus === 'Completed') {
    return {
      status: OFFLINE,
      color: OFFLINE_COLOR,
      tooltip: <OfflineTooltip />,
    };
  }

  // Pending provisioning
  if (!isAlive && dataSyncStatus === 'UnKnown') {
    return {
      status: PENDING_PROVISION,
      color: PENDING_COLOR,
      tooltip: <PendingProvisionTooltip />,
    };
  }

  const { status: statusCode } = status;
  // Device deleted
  if (statusCode === 400) {
    return {
      status: DEVICE_DELETED,
      tooltip: <DeviceDeletedTooltip />,
    };
  }
  // Account deleted
  if (statusCode === 401) {
    return {
      status: ACCOUNT_DELETED,
      tooltip: <AccountDeletedTooltip />,
    };
  }

  // Provisioning
  if (!isAlive && (dataSyncStatus === 'Not Started' || dataSyncStatus === 'Initiated')) {
    return {
      status: PROVISION,
      color: PENDING_COLOR,
      tooltip: <ProvisionTooltip />,
    };
  }

  // Failed Connection
  if (dataSyncStatus === 'Failed') {
    return {
      status: FAILED_CONNECTION,
      color: FAILED_CONNECTION_COLOR,
      tooltip: <FailedConnectionTooltip />,
    };
  }

  return {
    status: NOT_CONNECTED,
    tooltip: <NotConnectedTooltip />,
  };
};
