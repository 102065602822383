import React, { useState, useEffect } from 'react';
import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import { useModal } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'components/common/Toast';
import { ErrorAlert } from 'components/common/Alert';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { RegistrationWrapper, ChooseLocationModal, Steps, Progresses } from 'components/Registration';
import { useProPortalSync, useHomeownerProcess } from 'components/Registration/hooks';

const Registration = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [{ partnerAccountId }] = useCurrentUser();
  const { Modal, openModal, closeModal } = useModal({
    newDesign: true,
  });

  const [step, setStep] = useState<number>(2);
  const [regDeviceState, setRegDeviceState] = useState<any>({});
  const [existingAccount, setExistingAccount] = useState<{
    isExisting: boolean;
    data?: { hhUserID: string; hhLocationID: string } | null;
  }>({ isExisting: false, data: null });

  const {
    startHomeownerProcess,
    resetProgress: resetHomeownerProcess,
    isShowProgresses,
    newDeviceRecordId,
    endedHomeownerProcess,
    homeownerProgress,
    homeownerResult,
  } = useHomeownerProcess({
    regDeviceState,
    existingAccount,
  });

  const {
    createProPortalRecord,
    handleAddressChange,
    handleCreateNewLocation,
    continueWithSelectedLocation,
    existingLocations,
    selectedLocationId,
    selectedPartnerCustomerId,
    isShowProCustomerProgress,
    proCustomerResult,
    proCustomerProgress,
    resetProcess: resetProPortalProcess,
  } = useProPortalSync({ openModal, closeModal, state: regDeviceState, newDeviceRecordId });

  useEffect(() => {
    resetForm();
  }, []);

  const resetForm = () => {
    setStep(1);
    setRegDeviceState({});

    resetProPortalProcess();
    resetHomeownerProcess();
  };

  const startRegistration = async () => {
    if (!partnerAccountId) {
      toast(ErrorAlert(t, 'You are not associated to any organization.'));
      return;
    }

    startHomeownerProcess();
  };

  useEffect(() => {
    if (endedHomeownerProcess && newDeviceRecordId) {
      createProPortalRecord();
    }
  }, [endedHomeownerProcess]);

  return (
    <DashboardLayout>
      <RegistrationWrapper>
        <Steps
          step={step}
          setStep={setStep}
          regDeviceState={regDeviceState}
          setRegDeviceState={setRegDeviceState}
          setExistingAccount={setExistingAccount}
          handleSubmit={startRegistration}
          resetForm={resetForm}
          existingAccount={existingAccount}
          isShowProgresses={isShowProgresses}
        />

        {isShowProgresses && (
          <Progresses
            homeownerProgress={homeownerProgress}
            homeownerResult={homeownerResult}
            isShowProCustomerProgress={isShowProCustomerProgress}
            proCustomerResult={proCustomerResult}
            proCustomerProgress={proCustomerProgress}
            regDeviceState={regDeviceState}
            resetForm={resetForm}
            selectedPartnerCustomerId={selectedPartnerCustomerId}
          />
        )}

        <ChooseLocationModal
          Modal={Modal}
          openModal={openModal}
          closeModal={closeModal}
          homeowner={regDeviceState.homeowner}
          continueWithSelectedLocation={continueWithSelectedLocation}
          existingLocations={existingLocations}
          selectedLocationId={selectedLocationId}
          handleAddressChange={handleAddressChange}
          handleCreateNewLocation={handleCreateNewLocation}
        />
      </RegistrationWrapper>
    </DashboardLayout>
  );
};

export default Registration;
