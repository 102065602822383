import { useSuspenseQuery_experimental as useSuspenseQuery, gql } from '@apollo/client';
import { PartnerRole, CurrentUserQuery } from '../__generated__/graphql';

const CurrentUser = gql`
  query CurrentUser {
    viewer {
      partnerUsers {
        edges {
          node {
            ... on PartnerUser {
              role
              partnerAccount {
                ... on PartnerAccount {
                  id
                  name
                  contactEmail
                  partnerProgramEnrollments {
                    edges {
                      node {
                        id
                        status
                        partnerProgram {
                          __typename
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export function useCurrentUser() {
  const { data } = useSuspenseQuery<CurrentUserQuery>(CurrentUser);

  const partnerUser = data.viewer?.partnerUsers?.edges[0]?.node;
  const isAdmin = partnerUser?.role?.includes(PartnerRole.COMPANY_ADMIN) || false;
  const isOwner = partnerUser?.role?.includes(PartnerRole.OWNER) || false;
  const partnerAccount = partnerUser?.partnerAccount;
  const partnerAccountId = partnerAccount?.id;
  const hasActivePartnerBrandProfile =
    partnerUser?.partnerAccount?.partnerProgramEnrollments?.edges?.filter(
      enrollment => enrollment?.node?.partnerProgram?.name === 'Homeowner App Branding'
    )?.[0]?.node?.status === 'ACTIVE';
  return [{ isAdmin, isOwner, partnerAccountId, partnerAccount, hasActivePartnerBrandProfile }];
}
