import React, { useRef, useEffect, useState } from 'react';
import { StyledPre } from './styles';

interface ExpandablePreProps {
  data: any;
  expanded: boolean;
  toggleExpanded: () => void;
}

export const ExpandablePre = React.memo(function ExpandablePre(props: ExpandablePreProps) {
  const { data, expanded, toggleExpanded } = props;
  const preRef = useRef<HTMLPreElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    if (preRef.current && preRef.current.scrollHeight > 50) {
      setIsOverflow(true);
    } else {
      setIsOverflow(false);
    }
  }, [data]);

  return (
    <StyledPre ref={preRef} expanded={expanded} isOverflow={isOverflow} onClick={toggleExpanded}>
      {JSON.stringify(data, null, 2)}
    </StyledPre>
  );
});
