import { gql } from '__generated__/gql';

const GetProfile = gql(`
  query Profile {
    viewer {
      id
      countryCode
      firstName
      lastName
      contactEmail
      countryCode
      photoUrl
      primaryPhoneNumber
      secondaryPhoneNumber
      partnerUsers {
        edges {
          node {
            ... on PartnerUser {
              role
              status
              partnerAccount {
                ... on PartnerAccount {
                  name
                  partnerBrandProfile {
                    logo
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

export default GetProfile;
