import { useEffect, useState } from 'react';
import { useDevice } from './useDeviceQuery';

export function useDebounceQuery(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const deviceData = useDevice(debouncedValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return deviceData;
}
