import { checkAdminStatus } from 'api';
import axios from 'axios';
import { useSuspenseQuery_experimental as useSuspenseQuery } from '@apollo/client';
import { useCustomAuthData } from 'components/AuthContext';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useEffect } from 'react';
import { useAuth } from 'utils/auth';
import { apiConfig } from '../../config';
import GetProfile from 'graphql/ProfileQuery';
import { ProfileQuery } from '__generated__/graphql';

const instance = axios.create({
  baseURL: apiConfig.apiHost || 'http://localhost:8080',
});

let initialSeconds = 0;

const getCurrentTimeInSeconds = () => Date.now() / 1000;

export const isExpired = (prevTimeStamp: string | null, timeToCompare: number) => {
  if (!prevTimeStamp) return true;
  const parsedTimeStamp = parseInt(prevTimeStamp);
  const difference = getCurrentTimeInSeconds() - parsedTimeStamp;
  return difference > timeToCompare;
};

export const getInitialTimeAndSaveInLocalStorage = async (flag?: boolean) => {
  initialSeconds = Date.now() / 1000;
  localStorage.setItem(flag ? 'initialSeconds' : 'initialSecondsServiceTitan', `${initialSeconds}`);
};

const AxiosInterceptor = ({ children }) => {
  const [{ partnerAccountId }] = useCurrentUser();
  const { setAuthData } = useCustomAuthData();
  const { getAccessTokenSilently, isAuthenticated } = useAuth();
  const { data } = useSuspenseQuery<ProfileQuery>(GetProfile, {});

  const reqInterceptor = async (req: any) => {
    const token = isAuthenticated ? await getAccessTokenSilently() : '';
    if (partnerAccountId) req.headers['company-id'] = partnerAccountId;
    if (token) req.headers['Authorization'] = `Bearer ${token}`;

    return req;
  };

  const resInterceptor = (res: any) => res;

  const errInterceptor = (error: any) => Promise.reject(error);

  const resErrInterceptor = async (err: any) => {
    const originalConfig = err.config;
    console.log(err.response);
    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  };

  const interceptorRequest = instance.interceptors.request.use(reqInterceptor, errInterceptor);
  const interceptorResponse = instance.interceptors.response.use(resInterceptor, resErrInterceptor);

  useEffect(() => {
    (async () => {
      const res = await checkAdminStatus(data?.viewer?.id);
      setAuthData(prevState => ({
        ...prevState,
        isResideoAdmin: res.isAdmin,
        hasPrivilege: res.hasPrivilege,
      }));
    })();

    return () => {
      instance.interceptors.response.eject(interceptorResponse);
      instance.interceptors.request.eject(interceptorRequest);
    };
  }, [partnerAccountId]);

  return children;
};

export default instance;
export { AxiosInterceptor };
