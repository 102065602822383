import React, { FC } from 'react';
import SideMenu from './SideMenu/SideMenu';
import { ChildContainer, ContentContainer, HeaderContainer } from './styles';

const DashboardLayout: FC = ({ children }) => {
  return (
    <ContentContainer>
      <div>
        <SideMenu />
      </div>
      <HeaderContainer>
        <ChildContainer>{children}</ChildContainer>
      </HeaderContainer>
    </ContentContainer>
  );
};

export default DashboardLayout;
