import React from 'react';
import { Container, SideMenuHeader, LinkWrapper, CustomLink } from './styles';
import { useLocation } from 'react-router';
import { useCustomAuthData } from 'components/AuthContext';
import { useRegistrationContext } from 'components/RegistrationContext';

const SideMenu = () => {
  const { pathname } = useLocation();
  const { isResideoAdmin, hasPrivilege } = useCustomAuthData();
  const { incrementKey } = useRegistrationContext();
  const handleNewDeviceClick = () => {
    incrementKey();
  };

  return (
    <Container>
      <SideMenuHeader>
        Device <br />
        Pre-Registration Tool
      </SideMenuHeader>
      <LinkWrapper>
        <CustomLink
          disabled={isResideoAdmin}
          isActive={pathname === '/registration'}
          to={isResideoAdmin ? '#' : '/registration'}
          onClick={handleNewDeviceClick}>
          New Device
        </CustomLink>
        <CustomLink isActive={pathname === '/'} to='/'>
          Completed Devices
        </CustomLink>
        {isResideoAdmin && (
          <>
            <CustomLink isActive={pathname === '/configurations'} to='/configurations'>
              Configurations
            </CustomLink>
            <CustomLink isActive={pathname === '/privilege'} to='/privilege'>
              Privileges
            </CustomLink>
            {/* <CustomLink isActive={pathname === '/gdr-report'} to='/gdr-report'>
              GDR Report
            </CustomLink> */}
          </>
        )}
        {hasPrivilege && (
          <CustomLink isActive={pathname === '/pro-portal-bulk-sync'} to='/pro-portal-bulk-sync'>
            Bulk Sync to Pro Portal
          </CustomLink>
        )}
      </LinkWrapper>
    </Container>
  );
};

export default SideMenu;
