import React from 'react';

// import { TEST_FLAG } from 'config/flags';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFeatureFlagsContext {
  //   TEST_FLAG: boolean;
}

const defaultFlags: IFeatureFlagsContext = {
  //   TEST_FLAG,
};

const FeatureFlagsContext = React.createContext<IFeatureFlagsContext>(defaultFlags);

export const FeatureFlagsProvider = ({ children }) => {
  const flags = defaultFlags;

  return <FeatureFlagsContext.Provider value={flags}>{children}</FeatureFlagsContext.Provider>;
};

export const useFeatureFlags = () => React.useContext(FeatureFlagsContext);
