import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Titled } from 'react-titled';
import { GlobalStyle } from '@resideo/blueprint-react';
import ThemePreferencesProvider from 'components/global/ThemePreferencesProvider';
import config, { ENABLE_MOCKS } from 'config';
import Router from 'Router';
import Routes from 'routes';
import { History } from 'history';

import { local } from 'utils/storage';
import ApolloGQLProvider from 'utils/ApolloGQLProvider';
import { MockAuth0Provider } from 'utils/auth/MockAuth0Provider';
import { Auth0Provider } from '@auth0/auth0-react';

import 'styles/fonts.css';
import 'styles/app.css';
import { AuthContext } from 'components/AuthContext';
import ManageWebSocket from 'ManageWebSocket';
import { WebSocketContext } from 'components/WebSocketContext';
import { FeatureFlagsProvider } from 'context/FeatureFlags';
import { RegistrationProvider } from 'components/RegistrationContext';

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const onRedirectCallback = appState => {
    history.replace(appState?.returnTo || window.location.pathname);
  };
  if (ENABLE_MOCKS) {
    return <MockAuth0Provider>{children}</MockAuth0Provider>;
  } else {
    return (
      <Auth0Provider
        audience={config.audience}
        domain={config.domain}
        clientId={config.clientId}
        onRedirectCallback={onRedirectCallback}
        redirectUri={window.location.origin}
        scope={config.scope}
        useRefreshTokens={true}>
        {children}
      </Auth0Provider>
    );
  }
};

const App: FC<{ history?: History }> = ({ history }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const prefLocale = local.getItem('pref-locale');
    if (prefLocale && prefLocale !== 'en') {
      i18n.changeLanguage(prefLocale);
    }
  }, [i18n]);

  return (
    <Router history={history}>
      <AuthProvider>
        <ApolloGQLProvider>
          <AuthContext>
            <ThemePreferencesProvider>
              <FeatureFlagsProvider>
                <Titled title={() => t('title')}>
                  <GlobalStyle />
                  <WebSocketContext>
                    <RegistrationProvider>
                      <Routes />
                    </RegistrationProvider>
                    <ManageWebSocket />
                  </WebSocketContext>
                </Titled>
              </FeatureFlagsProvider>
            </ThemePreferencesProvider>
          </AuthContext>
        </ApolloGQLProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
