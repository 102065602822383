import React, { FC, useEffect } from 'react';
import { useAuth } from 'utils/auth';
import PageLoading from 'components/common/PageLoading';

const SignOut: FC = (): JSX.Element => {
  const { isLoading, logout } = useAuth();

  useEffect(() => {
    if (!isLoading) {
      localStorage.clear();
      logout({ returnTo: window.location.origin });
    }
  }, [logout, isLoading]);

  return <PageLoading />;
};

export default SignOut;
