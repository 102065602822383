import React from 'react';
import { SwitchWrapper, SwitchLabel, SwitchControl, HiddenCheckbox, Slider, CheckedSlider } from './SwitchBox.style';

interface SwitchBoxProps {
  id?: string;
  name?: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  styles?: React.CSSProperties;
  label?: string;
}

export const SwitchBox = ({ id, label, name, checked, onChange, styles }: SwitchBoxProps) => (
  <SwitchWrapper style={{ ...styles }}>
    <SwitchLabel>{label || ''}</SwitchLabel>
    <SwitchControl htmlFor={id}>
      <HiddenCheckbox id={id} name={name} checked={checked} onChange={onChange} />
      {checked ? <CheckedSlider /> : <Slider />}
    </SwitchControl>
  </SwitchWrapper>
);
