import React, { useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  PartnerCustomerCreateMutation,
  PartnerDeviceLocationCreateMutation,
  InstallationPartnerDeviceServiceCreateMutation,
  PartnerCustomersQuery,
  BrandingPartnerDeviceServiceCreateMutation,
  ComfortDeviceHandoffToConsumerMutation,
  PartnerCustomer,
} from '__generated__/graphql';
import PartnerCustomerCreate from 'graphql/PartnerCustomerCreateMutation';
import PartnerDeviceLocationCreate from 'graphql/PartnerDeviceLocationCreateMutation';
import InstallationPartnerDeviceServiceCreate from 'graphql/InstallationPartnerDeviceServiceCreateMutation';
import BrandingPartnerDeviceServiceCreate from 'graphql/BrandingPartnerDeviceServiceCreateMutation';
import GetPartnerCustomers from 'graphql/PartnerCustomersQuery';
import ComfortDeviceHandoffToConsumer from 'graphql/ComfortDeviceHandoffToConsumerMutation';
import { Feedback, PartnerDeviceLocation, ProCustomerProgress, Status } from '../interfaces';
import { proCustomerInitProgress, proCustomerProgressMsg } from '../constants';
import { getCurrentFormattedTime } from 'utils/datetime';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { updateDevice } from 'api';

const initProcessResult = { status: Status.Pending, message: '' };

export const useProPortalSync = ({ openModal, closeModal, state, newDeviceRecordId }) => {
  const [createPartnerCustomer] = useMutation<PartnerCustomerCreateMutation>(PartnerCustomerCreate);
  const [createPartnerDeviceLocation] = useMutation<PartnerDeviceLocationCreateMutation>(PartnerDeviceLocationCreate);
  const [installPartnerDevice] = useMutation<InstallationPartnerDeviceServiceCreateMutation>(
    InstallationPartnerDeviceServiceCreate
  );
  const [addBrandingPartnerDeviceServiceInstallation] = useMutation<BrandingPartnerDeviceServiceCreateMutation>(
    BrandingPartnerDeviceServiceCreate
  );
  const [comfortDeviceHandoffToConsumer] = useMutation<ComfortDeviceHandoffToConsumerMutation>(
    ComfortDeviceHandoffToConsumer
  );
  const [getPartnerCustomers] = useLazyQuery<PartnerCustomersQuery>(GetPartnerCustomers);

  const [{ partnerAccountId, partnerAccount, hasActivePartnerBrandProfile }] = useCurrentUser();

  const [proCustomerProgress, setProCustomerProgress] = useState<ProCustomerProgress>({ ...proCustomerInitProgress });
  const [proCustomerResult, setProCustomerResult] = useState<Feedback>(initProcessResult);
  const [selectedLocationId, setSelectedLocationId] = useState<string>();
  const [selectedPartnerCustomerId, setSelectedPartnerCustomerId] = useState<string>();
  const [selectedPartnerCustomer, setSelectedPartnerCustomer] = useState<PartnerCustomer>();
  const [existingLocations, setExistingLocations] = useState<PartnerDeviceLocation[]>();
  const [isShowProCustomerProgress, setIsShowProCustomerProgress] = useState<boolean>(false);

  /** Sends invite handoff. */
  const onComfortDeviceHandoffToConsumer = (installationPartnerDeviceServiceId, partnerCustomer) => {
    comfortDeviceHandoffToConsumer({
      variables: {
        input: {
          installationPartnerDeviceServiceId,
          claimStatus: 'PRO_CLAIM',
          deviceType: 'homes.d.t10', // hard coded for the moment.
          deviceVariant: 'homes.dv.t10na', // FlyCatcher_NA
          firstName: partnerCustomer.firstName,
          lastName: partnerCustomer.lastName,
          customerEmail: partnerCustomer.email,
          partnerDeviceId: state.macID,
          installedDate: new Date().toDateString(),
          companyName: partnerAccount?.name,
          companyEmail: partnerAccount?.contactEmail,
        },
      },
      onCompleted: ({ ComfortDeviceHandoffToConsumer }) => {
        if (ComfortDeviceHandoffToConsumer?.errors && ComfortDeviceHandoffToConsumer?.errors?.length > 0) {
          console.error('Error Creating Device Handoff:', ComfortDeviceHandoffToConsumer?.errors[0]?.message);
        }
      },
      onError: ({ message }) => {
        console.error(message);
      },
    });
  };

  const onInstallPartnerDevice = (partnerDeviceLocationId, partnerCustomer) => {
    const onFailed = (message: React.ReactNode = undefined) => {
      setProCustomerProgress(prev => {
        const newProgress = prev;
        newProgress.addDeviceToLocation.status = Status.Failed;
        newProgress.addDeviceToService.status = Status.Cancelled;
        return { ...newProgress };
      });
      setProCustomerResult({
        status: Status.Failed,
        message: message || (
          <>
            A system error occurred while adding the device to a customer location. Please contact Resideo support at
            <a href='mailto:proiqsupport@resideo.com'>proiqsupport@resideo.com</a> or 1-800-468-1502 to help resolve
            this issue.
          </>
        ),
      });
    };

    if (!state.deviceId) {
      onFailed(
        <>
          Unable to obtain the device for the macID. Please contact Resideo support at{' '}
          <a href='mailto:proiqsupport@resideo.com'>proiqsupport@resideo.com</a> or 1-800-468-1502 to help resolve this
          issue.
        </>
      );
      return;
    }

    if (!partnerDeviceLocationId) {
      onFailed();
      return;
    }

    // call install device gql
    installPartnerDevice({
      variables: {
        input: {
          deviceId: state.deviceId,
          installedAt: getCurrentFormattedTime(),
          partnerDeviceLocationId,
          status: 'ACTIVE',
        },
      },
      onCompleted: ({ installationPartnerDeviceServiceCreate }) => {
        if (installationPartnerDeviceServiceCreate) {
          setProCustomerProgress(prev => {
            const newProgress = prev;
            newProgress.addDeviceToLocation.status = Status.Success;
            newProgress.addDeviceToService.status = Status.Processing;
            return { ...newProgress };
          });

          // call update device api
          updateDevice(newDeviceRecordId, { partnerCustomerAccountId: partnerCustomer.id })
            .then(() => {
              onCreateBrandingPartnerDeviceService(
                partnerDeviceLocationId,
                installationPartnerDeviceServiceCreate.deviceService?.id,
                partnerCustomer
              );
            })
            .catch(err => {
              let errorMessage = '';
              if (err.response && err.response.status === 400) {
                errorMessage = err.response.data.description;
              } else {
                errorMessage = 'Something went wrong while updating the device with PartnerCustomerId.';
              }

              onCreateBrandingPartnerDeviceService(
                partnerDeviceLocationId,
                installationPartnerDeviceServiceCreate.deviceService?.id,
                partnerCustomer
              );

              console.error(errorMessage);
            });
        } else {
          onFailed();
        }
      },
      onError: ({ message }) => {
        console.error(message);
        onFailed();
      },
    });
  };

  const onCreateBrandingPartnerDeviceService = (
    partnerDeviceLocationId,
    installationPartnerDeviceServiceId,
    partnerCustomer
  ) => {
    const onFailed = (message: React.ReactNode = undefined) => {
      setProCustomerProgress(prev => {
        const newProgress = prev;
        newProgress.addDeviceToService.status = Status.Failed;
        return { ...newProgress };
      });
      setProCustomerResult({
        status: Status.Failed,
        message: message || (
          <>
            A system error occurred while adding the device to a customer location. Please contact Resideo support at
            <a href='mailto:proiqsupport@resideo.com'>proiqsupport@resideo.com</a> or 1-800-468-1502 to help resolve
            this issue.
          </>
        ),
      });
    };

    if (!state?.deviceId) {
      onFailed(
        <>
          Unable to obtain the device for the macID. Please contact Resideo support at{' '}
          <a href='mailto:proiqsupport@resideo.com'>proiqsupport@resideo.com</a> or 1-800-468-1502 to help resolve this
          issue.
        </>
      );
      return;
    }

    if (!hasActivePartnerBrandProfile) {
      setProCustomerProgress(prev => {
        const newProgress = prev;
        newProgress.addDeviceToService.status = Status.Cancelled;
        newProgress.addDeviceToService.message = 'No service(s) available to apply';
        return { ...newProgress };
      });
      setProCustomerResult({ status: Status.Success, message: '' });

      return;
    }

    // call create branding partner device service gql
    addBrandingPartnerDeviceServiceInstallation({
      variables: {
        input: {
          deviceId: state.deviceId,
          installedAt: getCurrentFormattedTime(),
          partnerDeviceLocationId,
          status: 'ACTIVE',
        },
      },
      onCompleted: response => {
        const errors = response?.brandingPartnerDeviceServiceCreate?.errors;

        if (errors?.length) {
          const message = errors[0]?.message;
          if (message === 'Cannot have more than one active BrandingPartnerDeviceService for Device') {
            setProCustomerProgress(prev => {
              const newProgress = prev;
              newProgress.addDeviceToService.status = Status.Cancelled;
              newProgress.addDeviceToService.message = 'Unable to add service to device';
              return { ...newProgress };
            });
            setProCustomerResult({ status: Status.Success, message: '' });
            return;
          } else {
            console.error(message);
            onFailed();
          }
        } else {
          setProCustomerProgress(prev => {
            const newProgress = prev;
            newProgress.addDeviceToService.status = Status.Success;
            return { ...newProgress };
          });

          onComfortDeviceHandoffToConsumer(installationPartnerDeviceServiceId, partnerCustomer);
        }

        // end progress
        setProCustomerResult({ status: Status.Success, message: '' });
      },
      onError: e => {
        console.error(e);
        onFailed();
      },
    });
  };

  const onCreatePartnerDeviceLocation = (partnerCustomerId, partnerCustomer) => {
    const onFailed = () => {
      setProCustomerProgress(prev => {
        const newProgress = prev;
        newProgress.createLocation.status = Status.Failed;
        newProgress.addDeviceToLocation.status = Status.Cancelled;
        newProgress.addDeviceToService.status = Status.Cancelled;
        return { ...newProgress };
      });
      setProCustomerResult({
        status: Status.Failed,
        message: (
          <>
            A system error occurred while creating/verifying the customer location. Please contact Resideo support at
            <a href='mailto:proiqsupport@resideo.com'>proiqsupport@resideo.com</a> or 1-800-468-1502 to help resolve
            this issue.
          </>
        ),
      });
    };

    if (!partnerCustomerId) {
      onFailed();
      return;
    }

    // call create device location gql
    createPartnerDeviceLocation({
      variables: {
        input: {
          address: {
            addressLine2: '',
            addressLine1: state.serviceTitanData.street,
            city: state.serviceTitanData.city,
            contactName: state.technician,
            countryCode: 'US',
            stateProvinceRegionCode: state.state,
            zipPostalCode: state.zip,
          },
          name: state.locationName,
          partnerAccountId,
          partnerCustomerId,
        },
      },
      onCompleted: ({ partnerDeviceLocationCreate }) => {
        if (partnerDeviceLocationCreate) {
          const partnerDeviceLocationId = partnerDeviceLocationCreate?.deviceLocation?.id;
          setProCustomerProgress(prev => {
            const newProgress = prev;
            newProgress.createLocation.status = Status.Success;
            newProgress.addDeviceToLocation.status = Status.Processing;
            return { ...newProgress };
          });
          onInstallPartnerDevice(partnerDeviceLocationId, partnerCustomer);
        } else {
          onFailed();
        }
      },
      onError: ({ message }) => {
        console.error(message);
        onFailed();
      },
    });
  };
  const onCreatePartnerCustomer = () => {
    const onFailed = () => {
      setProCustomerProgress(prev => {
        const newProgress = prev;
        newProgress.createCustomer.status = Status.Failed;
        newProgress.createLocation.status = Status.Cancelled;
        newProgress.addDeviceToLocation.status = Status.Cancelled;
        newProgress.addDeviceToService.status = Status.Cancelled;
        return { ...newProgress };
      });
      setProCustomerResult({
        status: Status.Failed,
        message: (
          <>
            A system error occurred while creating/verifying the customer account. Please contact Resideo support at
            <a href='mailto:proiqsupport@resideo.com'>proiqsupport@resideo.com</a> or 1-800-468-1502 to help resolve
            this issue.
          </>
        ),
      });
    };
    createPartnerCustomer({
      variables: {
        input: {
          address: {
            addressLine2: state.locationName,
            addressLine1: state.serviceTitanData.street,
            city: state.serviceTitanData.city,
            contactName: state.technician,
            countryCode: 'US',
            mailingAddressName: state.homeowner,
            stateProvinceRegionCode: state.state,
            zipPostalCode: state.zip,
          },
          email: state.email,
          firstName: state.firstName,
          lastName: state.lastName,
          partnerAccountNodeId: partnerAccountId,
          phoneNumber: '',
        },
      },
      onCompleted: ({ partnerCustomerCreate }) => {
        if (partnerCustomerCreate) {
          const partnerCustomerId = partnerCustomerCreate?.partnerCustomer?.id || '';
          setSelectedPartnerCustomerId(partnerCustomerId);
          setSelectedPartnerCustomer(partnerCustomerCreate?.partnerCustomer as PartnerCustomer);

          setProCustomerProgress(prev => {
            const newProgress = prev;
            newProgress.createCustomer.status = Status.Success;
            newProgress.createLocation.status = Status.Processing;
            return { ...newProgress };
          });

          onCreatePartnerDeviceLocation(partnerCustomerId, partnerCustomerCreate?.partnerCustomer);
        } else {
          onFailed();
        }
      },
      onError: ({ message }) => {
        console.error(message);
        onFailed();
      },
    });
  };

  const checkAddressMatch = (locations: PartnerDeviceLocation[]) => {
    // Function to extract the first number from an address string
    const extractFirstNumber = (str): number | null => {
      const match = str.match(/\d+/);
      return match ? parseInt(match[0]) : null;
    };

    // Extract the house number from the homeowner's address
    const homeownerHouseNumber = extractFirstNumber(state.homeowner.split(', ')[0]) || null;

    // Find a location with a matching house number
    const match = locations.find(location => {
      if (!homeownerHouseNumber) return false;
      const locationHouseNumber = extractFirstNumber(location?.address?.addressLine1);
      return homeownerHouseNumber === locationHouseNumber;
    });

    return match;
  };

  const createProPortalRecord = async () => {
    if (!state.proPortalEnabled) {
      setProCustomerResult({ status: Status.Success, message: '' });
      return;
    }
    setIsShowProCustomerProgress(true);
    setProCustomerResult({ status: Status.Processing, message: '' });

    // create partner customer
    setProCustomerProgress(prev => {
      const newProgress = prev;
      newProgress.createCustomer.status = Status.Processing;
      return { ...newProgress };
    });

    getPartnerCustomers({
      variables: {
        partnerAccountNodeId: partnerAccountId,
        filterBy: [
          {
            email: state.email,
          },
        ],
      },
      fetchPolicy: 'network-only',
      onCompleted: ({ partnerCustomers }) => {
        if (partnerCustomers.edges.length) {
          setProCustomerProgress(prev => {
            const newProgress = prev;
            newProgress.createCustomer.status = Status.Cancelled;
            newProgress.createCustomer.message = 'Customer account exists...';
            return { ...newProgress };
          });

          const locations: PartnerDeviceLocation[] = partnerCustomers.edges[0].node.deviceLocations?.edges
            .map(location => {
              const address = location?.node?.address;
              const locationId = location?.node?.id;
              return {
                address,
                partnerCustomerId: partnerCustomers.edges[0].node.id,
                id: locationId,
              };
            })
            .filter(el => el.id && el.address) as PartnerDeviceLocation[];

          setExistingLocations(locations);
          // check location matching
          const matchedLocation = checkAddressMatch(locations);
          if (matchedLocation) {
            setProCustomerProgress(prev => {
              const newProgress = prev;
              newProgress.createLocation.status = Status.Cancelled;
              newProgress.createLocation.message = 'Location exists...';
              newProgress.addDeviceToLocation.status = Status.Processing;
              return { ...newProgress };
            });

            setSelectedPartnerCustomerId(matchedLocation.partnerCustomerId || '');
            // matched with existing location
            onInstallPartnerDevice(matchedLocation?.id, partnerCustomers.edges[0].node);
          } else {
            // Need manual existing location select, not matched with existing location
            setSelectedPartnerCustomerId(partnerCustomers.edges[0]?.node?.id as string);
            setSelectedPartnerCustomer(partnerCustomers.edges[0].node as PartnerCustomer);
            openModal();
          }
        } else {
          // None existing partner customer
          onCreatePartnerCustomer();
        }
      },
      onError: error => {
        console.error(error);
        setProCustomerProgress(prev => {
          const newProgress = prev;
          newProgress.createCustomer.status = Status.Failed;
          newProgress.createLocation.status = Status.Cancelled;
          newProgress.addDeviceToLocation.status = Status.Cancelled;
          newProgress.addDeviceToService.status = Status.Cancelled;
          return { ...newProgress };
        });
        setProCustomerResult({
          status: Status.Failed,
          message: (
            <>
              A system error occurred while retrieving the customer account. Please contact Resideo support at{' '}
              <a href='mailto:proiqsupport@resideo.com'>proiqsupport@resideo.com</a> or 1-800-468-1502 to help resolve
              this issue.
            </>
          ),
        });
      },
    });
  };

  const handleAddressChange = event => {
    const selectedPartnerLocation = existingLocations?.find(location => location.id === event.target.value);
    setSelectedPartnerCustomerId(selectedPartnerLocation?.partnerCustomerId);
    setSelectedLocationId(event.target.value);
  };

  const handleCreateNewLocation = () => {
    closeModal();
    setProCustomerProgress(prev => {
      const newProgress = prev;
      newProgress.createLocation.message = 'Creating new location...';
      newProgress.createLocation.status = Status.Processing;
      return { ...newProgress };
    });

    onCreatePartnerDeviceLocation(selectedPartnerCustomerId, selectedPartnerCustomer);
  };

  const continueWithSelectedLocation = event => {
    event.preventDefault();
    closeModal();

    setProCustomerProgress(prev => {
      const newProgress = prev;
      newProgress.createLocation.status = Status.Cancelled;
      newProgress.createLocation.message = 'Existing location selected...';
      newProgress.addDeviceToLocation.status = Status.Processing;
      return { ...newProgress };
    });

    onInstallPartnerDevice(selectedLocationId, selectedPartnerCustomer);
  };

  const resetProcess = () => {
    setProCustomerResult({ ...initProcessResult });
    setProCustomerProgress(prev => {
      const newProgress = prev;
      newProgress.createCustomer.message = proCustomerProgressMsg.createCustomer;
      newProgress.createCustomer.status = Status.Pending;
      newProgress.createLocation.message = proCustomerProgressMsg.createLocation;
      newProgress.createLocation.status = Status.Pending;
      newProgress.addDeviceToLocation.message = proCustomerProgressMsg.addDeviceToLocation;
      newProgress.addDeviceToLocation.status = Status.Pending;
      newProgress.addDeviceToService.message = proCustomerProgressMsg.addDeviceToService;
      newProgress.addDeviceToService.status = Status.Pending;
      return { ...newProgress };
    });

    setSelectedLocationId('');
    setSelectedPartnerCustomerId('');
    setSelectedPartnerCustomer(undefined);
    setExistingLocations([]);
    setIsShowProCustomerProgress(false);
  };

  return {
    createProPortalRecord,
    handleAddressChange,
    handleCreateNewLocation,
    continueWithSelectedLocation,
    existingLocations,
    selectedLocationId,
    selectedPartnerCustomerId,
    isShowProCustomerProgress,
    proCustomerResult,
    proCustomerProgress,
    resetProcess,
  };
};
