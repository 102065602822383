import React from 'react';
import { ReactComponent as CaretUpComponent } from '../../../images/caret-up.svg';
import { ReactComponent as CaretDownComponent } from '../../../images/caret-down.svg';

export const TableHead = ({ columns, sortParam }: any) => {
  return (
    <thead>
      <tr>
        {columns.map((node: any) => (
          <HeaderCell key={node.label} {...node} colKey={node.key} sortParam={sortParam} width={node.width} />
        ))}
      </tr>
    </thead>
  );
};

const HeaderCell = ({
  label,
  onClickHandler,
  caretState,
  colKey,
  sortParam,
  width,
}: {
  label: string;
  onClickHandler?: (e?: any) => void;
  caretState: boolean;
  sortParam: string;
  colKey: string;
  width: string;
}) => {
  return (
    <th onClick={() => onClickHandler && onClickHandler()} style={width ? { width } : {}}>
      {label}
      {typeof caretState !== 'undefined' &&
        (caretState ? (
          <CaretUpComponent style={{ height: '15px', fill: sortParam === colKey ? 'black' : 'grey', width: '20px' }} />
        ) : (
          <CaretDownComponent
            style={{ height: '15px', fill: sortParam === colKey ? 'black' : 'grey', width: '20px' }}
          />
        ))}
    </th>
  );
};
