import React from 'react';
import { ProgressesContainer } from '../styles';
import { HomeownerProgresses } from './HomeownerProgresses';
import { ProPortalProgresses } from './ProPortalProgresses';
import { ProgressesResult } from './ProgressesResult';
import { Status } from '../interfaces';

export const Progresses = ({
  homeownerProgress,
  homeownerResult,
  isShowProCustomerProgress,
  proCustomerResult,
  proCustomerProgress,
  regDeviceState,
  resetForm,
  selectedPartnerCustomerId,
}) => {
  return (
    <div>
      <ProgressesContainer>
        <HomeownerProgresses homeownerProgress={homeownerProgress} homeownerResult={homeownerResult} />
        {isShowProCustomerProgress && (
          <ProPortalProgresses proCustomerResult={proCustomerResult} proCustomerProgress={proCustomerProgress} />
        )}
      </ProgressesContainer>
      {proCustomerResult.status === Status.Success && (
        <ProgressesResult
          regDeviceState={regDeviceState}
          resetForm={resetForm}
          selectedPartnerCustomerId={selectedPartnerCustomerId}
        />
      )}
    </div>
  );
};
