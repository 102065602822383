import React from 'react';
import { DetailContainer, StyledButton } from '../styles';

export const StepThree = ({ regDeviceState, setStep, resetForm, handleSubmit, existingAccount }) => {
  const { macID, locationName, firstName, lastName, deviceName, email, zip } = regDeviceState;

  return (
    <div>
      <h2
        style={{
          textAlign: 'center',
        }}>
        Does this look correct?
      </h2>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          height: '300px',
        }}>
        <div>
          <DetailContainer>
            <h3>Device Details</h3>
            <div>
              <p>
                <span>MAC ID:</span> {macID}
              </p>
              <p>
                <span>Location Name:</span> {locationName}
              </p>
              <p>
                <span>Device Name</span> {deviceName}
              </p>
            </div>
          </DetailContainer>
        </div>
        <DetailContainer>
          <h3>Customer Details</h3>
          <div>
            <p>
              <span>First Name:</span> {firstName}
            </p>
            <p>
              <span>Last Name:</span> {lastName}
            </p>
            <p>
              <span>Email Address:</span> {email}
            </p>
            <p>
              <span>Zip code:</span> {zip}
            </p>
          </div>
        </DetailContainer>
      </div>
      {existingAccount.isExisting && (
        <div>
          <h5 style={{ textAlign: 'center', color: 'rgb(0, 169, 224)' }}>
            NOTE: The homeowner already has an account associated with the above email address. <br /> The device about
            to be registered will be associated with the existing account and location.
          </h5>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
        }}>
        <div
          style={{
            textAlign: 'center',
          }}>
          <StyledButton
            onClick={() => {
              setStep(2);
            }}
            variant='secondary'>
            Back
          </StyledButton>
          <StyledButton
            style={{
              marginLeft: '20px',
            }}
            onClick={() => {
              handleSubmit();
            }}
            variant='primary'>
            Submit
          </StyledButton>
          <div
            style={{
              marginTop: '20px',
            }}>
            <a
              style={{
                color: '#00a9e0',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => {
                resetForm();
              }}>
              Cancel
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
