import React, { FC } from 'react';
import { Box, Flex } from '@resideo/blueprint-react';
import AuthenticatedHeader from 'components/header/AuthenticatedHeader';
import AnonymousHeader from 'components/header/AnonymousHeader';
import { Footer } from 'components/Footer';
import { useAuth } from 'utils/auth';

const AppLayout: FC = ({ children }): JSX.Element => {
  const auth = useAuth();
  return (
    <Flex overflowY={'hidden'} flexDirection='column' height='100vh'>
      {auth && auth.isAuthenticated && !auth.isLoading ? <AuthenticatedHeader /> : <AnonymousHeader />}
      <Flex flex='1'>
        <Box as='main' flex='1'>
          {children}
        </Box>
      </Flex>
      <Footer />
    </Flex>
  );
};
export default AppLayout;
