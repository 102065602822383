import { KEY_IO_CLIENT_USERNAME } from 'constants/socket.io';
import instance from './components/AxiosInterceptor';

export async function getRegistrations(
  startDate = '',
  endDate = '',
  searchString = '',
  orderByCreatedAt = '',
  needStatus = 1
) {
  let query = searchString ? `searchString=${searchString}` : `startDate=${startDate}&endDate=${endDate}`;
  if (orderByCreatedAt) query += `&orderByCreatedAt=${orderByCreatedAt}`;
  query += `&needStatus=${needStatus}`;
  const result = await instance({
    method: 'get',
    url: `/pre-registration?${query}`,
  });

  return result;
}

export async function getGDRReport(startDate = '', endDate = '') {
  const query = `startDate=${startDate}&endDate=${endDate}`;
  const result = await instance({
    method: 'get',
    url: `/gdr-report?${query}`,
  });

  return result;
}

export async function checkAdminStatus(userId) {
  const { data } = await instance({
    method: 'get',
    url: `/config/check-perm?userId=${userId}`,
  });
  return data;
}

export async function getRegistrationStatus(location: string, macID: string, userID: string) {
  try {
    const { data } = await instance.post(`/api-gee/get-status`, {
      macID,
      hhLocationID: location,
      hhUserID: userID,
    });
    return data;
  } catch (error) {
    return { err: error };
  }
}

export async function getJob(searchValue: string) {
  const { data } = await instance.post('/service-titan/get-job', {
    searchValue,
  });
  return data;
}

export async function getAppointment(appointmentID: string) {
  const { data } = await instance.post('/service-titan/get-appointment', {
    appointmentID,
  });

  return data;
}

export async function getCustomer(customerID: string) {
  const { data } = await instance.post('/service-titan/get-customer', {
    customerID,
  });
  return data;
}

export async function getCustomerData(customerID: string) {
  const { data } = await instance.post('/service-titan/get-customer-contact-data', {
    customerID,
  });
  return data;
}

export async function getJobAppointmentData(jobID: string) {
  const { data } = await instance.post('/service-titan/get-job-appointment-data', {
    jobID,
  });
  return data;
}

export const createLocation = async (data: any) => {
  const { locationName: name, zip: zipcode } = data;
  const { data: createLocatonData } = await instance.post(`/api-gee/create-location`, {
    ...data,
    name,
    zipcode: data?.changedData?.zip || zipcode,
    country: 'US',
    validateAddress: true,
  });
  return createLocatonData;
};

export const createDeviceRegistration = async (data: any) => {
  const { data: createdDeviceData } = await instance.post(`/api-gee/register-device`, {
    ...data,
  });
  return createdDeviceData;
};

export const createRegistration = async (data: any, wsClientUsername: string) => {
  const { data: createdRegistration } = await instance.post(
    `/pre-registration/device/create`,
    {
      ...data,
    },
    { headers: { [`${KEY_IO_CLIENT_USERNAME}`]: wsClientUsername } }
  );
  return createdRegistration;
};

export const updateDevice = async (id: number, data: any) => {
  const result = await instance.put(`/pre-registration/device?id=${id}`, {
    ...data,
  });
  return result;
};

export async function getDeviceByEmail(email: string, isMulti = false) {
  const { data } = await instance({
    method: 'get',
    url: `/pre-registration/device/${email}`,
  });
  const { devices } = data;
  return isMulti ? devices : devices?.[0];
}

export async function updateDevicePartnerCustomerId(id: number, partnerCustomerId: string) {
  const { data } = await instance({
    method: 'put',
    url: `/pre-registration/device?id=${id}`,
    data: {
      partnerCustomerAccountId: partnerCustomerId,
    },
  });
  return data;
}

export const createUser = async (data: any) => {
  const { data: createdUserData } = await instance.post(`/api-gee/create-user`, {
    ...data,
    email: data?.changedData?.email || data.email,
    zip: data?.changedData?.zip || data.zip,
    acceptedEulacountry: 'US',
    password: data?.password,
  });
  return createdUserData;
};

export const getActivationId = async (data: any) => {
  const { data: activationIdData } = await instance.post(`/api-gee/get-activation-id`, {
    ...data,
  });
  return activationIdData;
};

export const activateUser = async (data: any) => {
  const { data: userData } = await instance.post(`/api-gee/activate-user`, {
    ...data,
  });
  return userData;
};

export const fetchCompanyData = async () => {
  const { data } = await instance.get('/company-registration/');
  return data;
};

export const upsertCompanyData = async (companyData: any) => {
  const data = { ...companyData };
  const response = await instance.post('/company-registration/upsert', data);
  return response.data;
};

export const deleteCompanyApi = async id => {
  const response = await instance.delete('/company-registration/delete', {
    data: {
      id,
    },
  });
  return response.data;
};

export const fetchPrivilegeData = async () => {
  const { data } = await instance.get('/privilege-management');
  return data;
};

export const createPrivilegeData = async (companyData: any) => {
  const data = { ...companyData };
  const response = await instance.post('/privilege-management/create', data);
  return response.data;
};

export const updatePrivilegeData = async (companyData: any) => {
  const data = { ...companyData };
  const response = await instance.post('/privilege-management/update', data);
  return response.data;
};

export const deletePrivilegeApi = async id => {
  const response = await instance.delete('/privilege-management/delete', {
    data: {
      id,
    },
  });
  return response.data;
};

export const deleteUser = async (userID: any) => {
  const response = await instance.delete('/api-gee/delete-user', {
    data: {
      userID,
    },
  });
  return response.data;
};
