import React, { useEffect } from 'react';
import InfoIcon from '../../images/info-icon-filled.svg';
import { Dot, ToolTip } from './styles';

export const StatusCell = ({ status }) => {
  useEffect(() => {
    ToolTip.rebuild();
  });

  if (status.isUnknown) return null;

  return (
    <div
      data-tip
      data-for={status.status}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <ToolTip
        getContent={() => {
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {status.color && <Dot color={status.color} />}
                <p
                  style={{
                    margin: 0,
                    padding: '2px 10px',
                  }}>
                  {status.status}
                </p>
              </div>
              <div>{status.tooltip}</div>
            </>
          );
        }}
        id={status.status}
        arrowColor='transparent'
        className='custom-tooltip'
      />

      <div style={{ display: 'flex', alignItems: 'center' }}>
        {status.color && <Dot color={status.color} />}
        <p
          style={{
            margin: 0,
            fontSize: '14px',
            padding: '2px 10px 2px 6px',
          }}>
          {status.status}
        </p>
      </div>
      <img width={'15px'} src={InfoIcon} />
    </div>
  );
};
