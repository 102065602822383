import React, { ComponentType, lazy, FC } from 'react';
import { Switch } from 'react-router-dom';

import Route, { AnonymousRoute } from 'components/Route';
import AppLayout from 'components/AppLayout';
import PageError from 'components/common/PageError';

import SignIn from 'routes/SignIn';
import SignOut from 'routes/SignOut';
import PreRegistrations from './PreRegistrations';
import Registration from './Registration';
import AccessDenied from 'components/common/AccessDenied';
import Configurations from './Configurations';
import ProPortalBulkSync from './ProPortalBulkSync';
import Privilege from './Privilege';
import { useRegistrationContext } from 'components/RegistrationContext';

type LazyModulePromise = Promise<{ default: ComponentType<any> }>;

const Profile = lazy((): LazyModulePromise => import('./Profile'));

const Routes: FC = () => {
  const { registrationKey } = useRegistrationContext();

  return (
    <Switch>
      <AnonymousRoute path='/sign-out'>
        <SignOut />
      </AnonymousRoute>
      <AnonymousRoute
        path='/'
        render={() => (
          <AppLayout>
            <Switch>
              <AnonymousRoute path='/access-denied'>
                <AccessDenied />
              </AnonymousRoute>
              <AnonymousRoute path='/sign-in'>
                <SignIn />
              </AnonymousRoute>
              <Route exact path='/'>
                <PreRegistrations />
              </Route>
              <Route exact path='/registration'>
                <Registration key={registrationKey} />
              </Route>
              <Route exact path='/configurations'>
                <Configurations />
              </Route>
              <Route exact path='/pro-portal-bulk-sync'>
                <ProPortalBulkSync />
              </Route>
              <Route exact path='/privilege'>
                <Privilege />
              </Route>
              <Route exact path='/profile'>
                <Profile />
              </Route>
              <AnonymousRoute>
                <PageError />
              </AnonymousRoute>
            </Switch>
          </AppLayout>
        )}
      />
    </Switch>
  );
};

export default Routes;
