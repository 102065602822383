import { InMemoryCache, ApolloProvider, ApolloClient, createHttpLink, ApolloLink, SuspenseCache } from '@apollo/client';
import React, { useMemo } from 'react';
import { useAuth } from './auth';
import {
  GATEWAY_URL,
  USE_PERSISTED_QUERIES,
  PERSISTED_QUERIES_APP_NAME,
  PERSISTED_QUERIES_APP_VERSION,
  APOLLO_CLIENT_NAME,
} from 'config';
import persistedDocuments from '../../src/__generated__/persisted-documents.json';

export const encodeId = ({ appName, appVersion, md5Hash }) => btoa(`${appName}:${appVersion}:${md5Hash}`);

const suspenseCache = new SuspenseCache();

const ApolloGQLProvider = ({ children }): JSX.Element => {
  const uri = GATEWAY_URL;
  const { getAccessTokenSilently, isAuthenticated } = useAuth();

  const client = useMemo(() => {
    const uriFunction = md5Hash => {
      const hash = encodeId({
        appName: PERSISTED_QUERIES_APP_NAME,
        appVersion: PERSISTED_QUERIES_APP_VERSION,
        md5Hash,
      });
      return USE_PERSISTED_QUERIES ? `${uri}/${hash}` : uri;
    };

    const fetchFunction = async (resource, options) => {
      const parsedBody = await JSON.parse(options?.body);
      const splitQuery: string[] = parsedBody.query.split(' ');
      const signaturePrefix: string = splitQuery[0];
      const signatureSuffix: string = splitQuery[2] === '{\n' ? ' {' : '(';

      // Building the full call signature from the parsedBody so we won't have collisions with persisted queries
      const querySignature = `${signaturePrefix} ${parsedBody.operationName}${signatureSuffix}`;

      const parsedDocuments = await JSON.parse(JSON.stringify(persistedDocuments));

      const parsedKey = Object.keys(parsedDocuments).find(key => {
        return parsedDocuments[key].includes(querySignature);
      });

      // If the user isn't signed in (like on Create Account screen), getAccessTokenSilently
      // will fail.  That's why we check for isAuthenticated before running it.
      const token = isAuthenticated ? await getAccessTokenSilently() : null;

      const response = await fetch(uriFunction(parsedKey), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'apollographql-client-name': APOLLO_CLIENT_NAME,
          'apollographql-client-version': PERSISTED_QUERIES_APP_VERSION,
          authorization: token ? `Bearer ${token}` : '',
        },
        body: JSON.stringify({
          ...(!USE_PERSISTED_QUERIES && { query: parsedBody?.query }),
          variables: parsedBody?.variables,
        }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          // TODO; Call Auth0 with a refresh_token to get a new access_token?
          throw new Error('Received unauthorized status code from GraphQL');
        }
      }

      return response;
    };

    const httpLink = createHttpLink({ fetch: fetchFunction });
    const linkChain = ApolloLink.from([httpLink]);

    return new ApolloClient({
      link: linkChain,
      cache: new InMemoryCache({}),
    });
  }, [isAuthenticated]);

  return (
    <ApolloProvider client={client} suspenseCache={suspenseCache}>
      {children}
    </ApolloProvider>
  );
};

export default ApolloGQLProvider;
