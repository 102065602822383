import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';

// the translations
// (tip move them in a JSON file and import them)
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { common: en },
    },
    defaultNS: 'common',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.languages = ['en'];
