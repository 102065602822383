import styled from 'styled-components';

export const TableContainer = styled.div`
  th {
    background: rgb(237, 237, 237);
    border-radius: 0px;
    text-align: left;
    padding: 12px;
    color: rgb(0, 0, 0);
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    span {
      font-size: 20px;
      font-weight: bold;
    }
  }
  td {
    border-bottom: 2px solid rgb(219, 219, 219);
    padding: 20px 12px;
    color: rgb(34, 30, 31);
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 21.77px;
    width: 300px;
    a {
      color: #156488;
      font-weight: 600;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  background-color: white;
  margin: 50px 0;
  margin-top: 15px;
  margin-bottom: 0;
  padding: 0 25px;
`;

export const TableRow = styled.tr<{
  lastItem: boolean;
}>`
  td {
    ${({ lastItem }) => lastItem && 'border-bottom: none !important;'}
  }
  &:nth-child(even) {
    background: #fafafa;
  }
`;

export const NoRecords = styled.h3`
  text-align: center;
`;

export const ProCustomerDetail = styled.div`
  margin-top: 6px;
  color: #00a9e0 !important;
  display: flex;
  align-items: center;
  & > a {
    color: #00a9e0 !important;
    font-weight: 400 !important;
    margin-right: 4px;
  }
`;
