import { useRef, useState } from 'react';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useWebSocketData } from 'components/WebSocketContext';
import { useAuth } from 'utils/auth';
import { IOSocketMessageType } from 'utils/websockets';
import { IOEventTypes, IOEvents, IOStatus } from 'constants/socket.io';
import { Feedback, HomeownerProgress, Status } from '../interfaces';
import { homeownerInitProgress, progressMsg } from '../constants';

const initHomeownerResult = { status: Status.Pending, message: '' };

export const useHomeownerProcess = ({ regDeviceState, existingAccount }) => {
  let isStarted = false;
  const [{ partnerAccountId }] = useCurrentUser();
  const { getAccessTokenSilently } = useAuth();
  const { webSocketData } = useWebSocketData();
  const progressTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [isShowProgresses, setIsShowProgresses] = useState<boolean>(false);
  const [endedHomeownerProcess, setEndedHomeownerProcess] = useState<boolean>(false);
  const [homeownerProgress, setHomeownerProgress] = useState<HomeownerProgress>({ ...homeownerInitProgress });
  const [homeownerResult, setHomeownerResult] = useState<Feedback>(initHomeownerResult);
  const [newDeviceRecordId, setNewDeviceRecordId] = useState<number>(0);

  const stopProgress = (status: Status) => {
    setHomeownerProgress(progress => {
      const newProgress: HomeownerProgress = Object.keys(progress).reduce((acc, key) => {
        const step: Feedback = progress[key];
        if (step.status === Status.Processing) {
          return {
            ...acc,
            [key]: {
              ...step,
              status,
            },
          };
        }
        return {
          ...acc,
          [key]: step,
        };
      }, {} as HomeownerProgress);

      return { ...newProgress };
    });
  };

  const resetProgress = () => {
    setIsShowProgresses(false);
    setEndedHomeownerProcess(false);
    setHomeownerProgress(prev => {
      const newProgress = prev;
      newProgress.createUser.status = Status.Pending;
      newProgress.createUser.message = progressMsg.createUser;
      newProgress.createLocation.status = Status.Pending;
      newProgress.createLocation.message = progressMsg.createLocation;
      newProgress.createDevice.status = Status.Pending;
      newProgress.createDevice.message = progressMsg.createDevice;
      newProgress.getActivationId.status = Status.Pending;
      newProgress.getActivationId.message = progressMsg.getActivationId;
      newProgress.activeUser.status = Status.Pending;
      newProgress.activeUser.message = progressMsg.activeUser;
      return {
        ...newProgress,
      };
    });
    setHomeownerResult({ ...initHomeownerResult });
  };

  const endProgress = (result: Feedback) => {
    if (progressTimeout.current) clearTimeout(progressTimeout.current);
    setHomeownerResult(result);
  };

  const startProgress = () => {
    isStarted = true;
    setIsShowProgresses(true);
  };

  const renderNewDeviceStatusByWSMsg = (message: IOSocketMessageType) => {
    switch (message.type) {
      case IOEventTypes.CreateUser: {
        if (message.status === IOStatus.Started) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.createUser.status = Status.Processing;
            return {
              ...newProgress,
            };
          });
        } else if (message.status === IOStatus.Done) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.createUser.status = Status.Success;
            return {
              ...newProgress,
            };
          });
        } else if (message.status === IOStatus.Failed) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.createUser.status = Status.Failed;
            newProgress.createLocation.status = Status.Cancelled;
            newProgress.createDevice.status = Status.Cancelled;
            newProgress.getActivationId.status = Status.Cancelled;
            newProgress.activeUser.status = Status.Cancelled;
            return {
              ...newProgress,
            };
          });

          const err = message.data;
          if (err.fault?.faultstring == 'Access Token expired') {
            endProgress({ status: Status.Failed, message: 'Access Token expired' });
            return;
          }
          const errorMsgs = (err?.fields || []).map(node => {
            const { field } = node;
            if (field === 'user.Email') {
              return 'Unable to create homeowner account. An account already exists for the given email address.';
            }
            return node?.code || '';
          });
          endProgress({ status: Status.Failed, message: `${errorMsgs.join(', ')}.` });
        }
        break;
      }
      case IOEventTypes.CreateLocation: {
        if (message.status === IOStatus.Started) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.createLocation.status = Status.Processing;
            return {
              ...newProgress,
            };
          });
        } else if (message.status === IOStatus.Done) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.createLocation.status = Status.Success;
            return {
              ...newProgress,
            };
          });
        } else if (message.status === IOStatus.Failed) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.createLocation.status = Status.Failed;
            newProgress.createDevice.status = Status.Cancelled;
            newProgress.getActivationId.status = Status.Cancelled;
            newProgress.activeUser.status = Status.Cancelled;
            return {
              ...newProgress,
            };
          });

          const err = message.data;
          endProgress({ status: Status.Failed, message: `Error occurred while creating location. ${err?.message}` });
        }
        break;
      }
      case IOEventTypes.CreateDevice: {
        if (message.status === IOStatus.Started) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.createDevice.status = Status.Processing;
            return {
              ...newProgress,
            };
          });
        } else if (message.status === IOStatus.Done) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.createDevice.status = Status.Success;
            setNewDeviceRecordId(+message.data || 0);

            return {
              ...newProgress,
            };
          });
        } else if (message.status === IOStatus.Failed) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.createDevice.status = Status.Failed;
            newProgress.getActivationId.status = Status.Cancelled;
            newProgress.activeUser.status = Status.Cancelled;
            return {
              ...newProgress,
            };
          });

          const err = message.data;
          const errorMessage =
            err?.message === 'Device is not allowed to be registered' ? 'Device is already registered' : err?.message;
          endProgress({ status: Status.Failed, message: `Unable to register device. ${errorMessage}` });
        }
        break;
      }
      case IOEventTypes.GetActivationId: {
        if (message.status === IOStatus.Started) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.getActivationId.status = Status.Processing;
            return {
              ...newProgress,
            };
          });
        } else if (message.status === IOStatus.Done) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.getActivationId.status = Status.Success;
            return {
              ...newProgress,
            };
          });
        } else if (message.status === IOStatus.Failed) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.getActivationId.status = Status.Failed;
            newProgress.activeUser.status = Status.Cancelled;
            return {
              ...newProgress,
            };
          });

          endProgress({
            status: Status.Success,
            message: `The device pre-registration was successful. However, the homeowner account activation failed (unable to obtain Activation ID). The homeowner can manually activate their account by clicking the link in their Activation Email.`,
          });
          // start ProPortal Sync by HomeownerProcess ended flag
          setEndedHomeownerProcess(true);
        }
        break;
      }
      case IOEventTypes.ActiveUser: {
        if (message.status === IOStatus.Started) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.activeUser.status = Status.Processing;
            return {
              ...newProgress,
            };
          });
        } else if (message.status === IOStatus.Done) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.activeUser.status = Status.Success;
            return {
              ...newProgress,
            };
          });

          endProgress({ status: Status.Success, message: `` });
          // start ProPortal Sync by HomeownerProcess ended flag
          setEndedHomeownerProcess(true);
        } else if (message.status === IOStatus.Failed) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.activeUser.status = Status.Failed;
            return {
              ...newProgress,
            };
          });

          endProgress({
            status: Status.Success,
            message: `The device pre-registration was successful. However, the homeowner account activation failed. The homeowner can manually activate their account by clicking the link in their Activation Email.`,
          });
          // start ProPortal Sync by HomeownerProcess ended flag
          setEndedHomeownerProcess(true);
        }
        break;
      }
      case IOEventTypes.DeleteUser: {
        if (message.status === IOStatus.Failed) {
          endProgress({
            ...homeownerResult,
            message: `${homeownerResult.message} Something went wrong while deleting a user as well.`,
          });
        }
        break;
      }
      case IOEventTypes.GetException: {
        stopProgress(Status.Failed);
        endProgress({ status: Status.Failed, message: `Something went wrong!` });
        break;
      }

      default:
        break;
    }
  };

  const renderAddDeviceStatusByWSMsg = (message: IOSocketMessageType) => {
    switch (message.type) {
      case IOEventTypes.CreateDevice: {
        if (message.status === IOStatus.Started) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.createUser.status = Status.Cancelled;
            newProgress.createLocation.status = Status.Cancelled;
            newProgress.createDevice.status = Status.Processing;
            return {
              ...newProgress,
            };
          });
        } else if (message.status === IOStatus.Done) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.createDevice.status = Status.Success;
            newProgress.getActivationId.status = Status.Cancelled;
            newProgress.activeUser.status = Status.Cancelled;
            return {
              ...newProgress,
            };
          });

          endProgress({ status: Status.Success, message: `` });
          setNewDeviceRecordId(+message.data || 0);

          // start ProPortal Sync by HomeownerProcess ended flag
          setEndedHomeownerProcess(true);
        } else if (message.status === IOStatus.Failed) {
          setHomeownerProgress(prev => {
            const newProgress = prev;
            newProgress.createDevice.status = Status.Failed;
            newProgress.getActivationId.status = Status.Cancelled;
            newProgress.activeUser.status = Status.Cancelled;
            return {
              ...newProgress,
            };
          });

          const err = message.data;
          const errorMessage =
            err?.message && err.message.toLowerCase().includes('device is not allowed to be registered')
              ? 'Device is already registered.'
              : err?.message;
          endProgress({ status: Status.Failed, message: `Unable to register device. ${errorMessage}` });
        }
        break;
      }
      case IOEventTypes.GetException: {
        stopProgress(Status.Failed);
        endProgress({ status: Status.Failed, message: `Something went wrong!` });
        break;
      }

      default:
        break;
    }
  };

  const startHomeownerProcess = async () => {
    if (webSocketData.isConnected && webSocketData.WebSocketClient) {
      const socket = webSocketData.WebSocketClient;

      const token = await getAccessTokenSilently();
      if (existingAccount.isExisting) {
        const userID = existingAccount.data?.hhUserID;
        const locationID = existingAccount.data?.hhLocationID;
        socket.emit(IOEvents.AddDevice, {
          username: webSocketData.username,
          type: IOEventTypes.CreateStart,
          status: IOStatus.Started,
          data: { ...regDeviceState, userID, locationID, partnerAccountId, token },
        });

        socket.on(IOEvents.AddDevice, message => {
          const { username } = message;
          if (isStarted && username === webSocketData.username) {
            renderAddDeviceStatusByWSMsg(message);
          }
        });
      } else {
        socket.emit(IOEvents.CreateDevice, {
          username: webSocketData.username,
          type: IOEventTypes.CreateStart,
          status: IOStatus.Started,
          data: { ...regDeviceState, partnerAccountId, token },
        });

        socket.on(IOEvents.CreateDevice, message => {
          const { username } = message;
          if (isStarted && username === webSocketData.username) {
            renderNewDeviceStatusByWSMsg(message);
          }
        });
      }

      startProgress();
      progressTimeout.current = setTimeout(() => {
        stopProgress(Status.Cancelled);
        endProgress({ status: Status.Failed, message: "Something went wrong! Can't finish the task on time." });
      }, 30 * 1000);
    }
  };

  return {
    startHomeownerProcess,
    resetProgress,
    isShowProgresses,
    newDeviceRecordId,
    endedHomeownerProcess,
    homeownerProgress,
    homeownerResult,
  };
};
