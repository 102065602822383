import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import App from './App';
import { ENABLE_MOCKS, IS_TEST, ENVIRONMENT, SENTRY_DSN, SENTRY_SAMPLE_RATE } from './config';

import { sentryInit } from '@resideo/sentry-web-react';

const isLocalhost = Boolean(window.location.hostname === 'localhost');

if (!IS_TEST && !isLocalhost && SENTRY_DSN) {
  sentryInit({
    dsn: SENTRY_DSN,
    tracesSampleRate: SENTRY_SAMPLE_RATE,
    environment: ENVIRONMENT,
  });
}

if (ENABLE_MOCKS && !IS_TEST) {
  (async () => {
    const { initializeMocks } = await import('./utils/mocks');
    await initializeMocks();
  })();
}

ReactDOM.render(<App />, document.getElementById('root'));
