import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useCustomAuthData } from 'components/AuthContext';

const AppGuard = ({ children, path }) => {
  const [{ partnerAccountId, isAdmin, isOwner }] = useCurrentUser();
  const { push } = useHistory();
  const { isResideoAdmin } = useCustomAuthData();

  useEffect(() => {
    if (isResideoAdmin) return;
    const userHasAccess = [isAdmin, isOwner].some(Boolean) && partnerAccountId;
    if (
      (isResideoAdmin && (path === '/configurations' || path === '/privilege')) ||
      (!userHasAccess && path !== '/profile')
    )
      push('/access-denied');
  }, [path]);

  return children;
};

export { AppGuard };
