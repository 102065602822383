import styled from 'styled-components';

export const SwitchControl = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;

  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

// Styled components for checked state
export const CheckedSlider = styled(Slider)`
  background-color: #166a96;

  &:before {
    transform: translateX(16px);
  }
`;

export const FocusSlider = styled(Slider)`
  box-shadow: 0 0 1px #166a96;
`;

export const SwitchWrapper = styled.div`
  display: flex;
`;

export const SwitchLabel = styled.label`
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  font-size: 0.875rem;
  margin-right: 8px;
`;
