import { gql } from '__generated__/gql';

const GetDeviceByNodeId = gql(`
  query DeviceByNodeId($deviceNodeId: String!) {
    getDeviceByNodeId(id: $deviceNodeId) {
      ... on LyricThermostatDevice {
        id
        deviceId
        deviceServices {
          edges {
            node {
              ... on InstallationPartnerDeviceService {
                __typename
                id
                installedAt
                createdAt
                status
                updatedAt
                deviceLocation {
                  id
                }
              }
              ... on BrandingPartnerDeviceService {
                __typename
                id
                installedAt
                createdAt
                status
                deletedAt
                deviceLocation {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`);

export default GetDeviceByNodeId;
