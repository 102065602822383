import { createContext } from 'react';
import { Auth0ContextInterface } from '@auth0/auth0-react';

export interface Session {
  accessToken: string;
  emailVerified: boolean;
}

export interface AuthenticationState {
  isAuthenticated: boolean;
  isLoading: boolean;
  setIsAuthenticated: (payload) => void;
  loginWithRedirect: () => Promise<void>;
  logout: () => Promise<void>;
  restoreSession: () => Promise<void>;
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'];
  getIdTokenClaims: Auth0ContextInterface['getIdTokenClaims'];
}

export default createContext<AuthenticationState>({
  isAuthenticated: false,
  isLoading: true,
  setIsAuthenticated: payload => payload,
  loginWithRedirect: () => new Promise(resolve => resolve),
  logout: () => new Promise(resolve => resolve),
  restoreSession: () => new Promise(resolve => resolve),
  getAccessTokenSilently: () => new Promise(resolve => resolve),
  getIdTokenClaims: () => new Promise(resolve => resolve),
});
