import React from 'react';
import { StepOne, StepTwo, StepThree } from '.';
import { StepWrapper } from '../styles';
import Stepper from 'components/Stepper/Stepper';

export const Steps = ({
  step,
  setStep,
  regDeviceState,
  setRegDeviceState,
  setExistingAccount,
  handleSubmit,
  resetForm,
  existingAccount,
  isShowProgresses,
}) => {
  return (
    <>
      <Stepper step={step} />
      {!isShowProgresses && (
        <StepWrapper>
          {
            {
              1: <StepOne state={regDeviceState} setStep={setStep} regDeviceState={setRegDeviceState} />,
              2: (
                <StepTwo
                  state={regDeviceState}
                  setStep={setStep}
                  regDeviceState={setRegDeviceState}
                  setExistingAccount={setExistingAccount}
                />
              ),
              3: (
                <StepThree
                  handleSubmit={handleSubmit}
                  resetForm={resetForm}
                  regDeviceState={regDeviceState}
                  setStep={setStep}
                  existingAccount={existingAccount}
                />
              ),
            }[step]
          }
        </StepWrapper>
      )}
    </>
  );
};
