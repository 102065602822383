import React, { createContext, useState, useContext, ReactNode } from 'react';

interface RegistrationContextProps {
  registrationKey: number;
  incrementKey: () => void;
}

const RegistrationContext = createContext<RegistrationContextProps | undefined>(undefined);

export const useRegistrationContext = (): RegistrationContextProps => {
  const context = useContext(RegistrationContext);
  if (!context) {
    throw new Error('useRegistrationContext must be used within a RegistrationProvider');
  }
  return context;
};

interface RegistrationProviderProps {
  children: ReactNode;
}

export const RegistrationProvider: React.FC<RegistrationProviderProps> = ({ children }) => {
  const [registrationKey, setRegistrationKey] = useState(0);

  const incrementKey = () => {
    setRegistrationKey(prevKey => prevKey + 1);
  };

  return (
    <RegistrationContext.Provider value={{ registrationKey, incrementKey }}>{children}</RegistrationContext.Provider>
  );
};
