import React from 'react';
import { TableBody, TableHead } from './Table';
import { Table, TableContainer, TableScrollView } from './Table/styles';
import PageLoading from 'components/common/PageLoading';
import { TableTitle } from './styles';

export const DevicesTable = ({ isLoading, columns, header: { sortParam }, body: { data } }) => {
  return (
    <TableContainer>
      <TableTitle>To be processed</TableTitle>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            flexDirection: 'column',
            height: '10vh',
            minWidth: '100px',
          }}>
          <PageLoading />
        </div>
      ) : (
        <TableScrollView>
          <Table cellPadding='0' cellSpacing='0'>
            <TableHead columns={columns} sortParam={sortParam} />
            <TableBody records={data} columns={columns} />
          </Table>
        </TableScrollView>
      )}
    </TableContainer>
  );
};
