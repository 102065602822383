import React from 'react';
import { Form, Formik } from 'formik';
import { InputField } from '@resideo/blueprint-formik';
import { Box, Flex } from '@resideo/blueprint-react';
import { ClearSearch, CustomLabel } from './styles';

export const Search = ({ setValue, setStatus }) => {
  return (
    <Formik
      onSubmit={values => {
        setStatus([]);
        setValue(values.search);
      }}
      onReset={() => {
        setValue('');
      }}
      initialValues={{
        search: '',
      }}>
      {({ resetForm, values }) => (
        <Form noValidate>
          <Flex marginBottom={25} alignItems={'center'} flexDirection={['column', 'row']}>
            <CustomLabel>Search</CustomLabel>
            <Box width={400}>
              <InputField label='' name='search' placeholder='Job / Homeowner / Tech / Email / MAC' />
              <ClearSearch>
                {values.search && (
                  <div
                    onClick={() => {
                      resetForm();
                      setStatus([]);
                    }}>
                    x
                  </div>
                )}
              </ClearSearch>
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
