import { Address } from '__generated__/graphql';

export enum Status {
  Pending = 'Pending',
  Processing = 'Processing',
  Cancelled = 'Cancelled',
  Failed = 'Failed',
  Success = 'Success',
}

export interface Feedback {
  status: Status;
  message: string | React.ReactNode;
}

export interface HomeownerProgress {
  createUser: Feedback;
  createLocation: Feedback;
  createDevice: Feedback;
  getActivationId: Feedback;
  activeUser: Feedback;
}

export interface ProCustomerProgress {
  createCustomer: Feedback;
  createLocation: Feedback;
  addDeviceToLocation: Feedback;
  addDeviceToService: Feedback;
}

export interface PartnerDeviceLocation {
  address: Address;
  partnerCustomerId: string;
  id: string;
}
