import styled from 'styled-components';
import { Button } from '@resideo/blueprint-react';
import ReactTooltip from 'react-tooltip';

export const CustomLabel = styled.label`
  font-size: 15px;
  width: 100px;
  color: white;
  text-align: right;
  margin-right: 15px;
`;

export const ClearSearch = styled.div`
  right: 25px;
  top: 8px;
  position: absolute;
  cursor: pointer;
  font-weight: bold;
`;

export const CustomButton = styled(Button)`
  background-color: white;
  border-radius: 2rem;
  border: 2px solid rgb(22, 106, 150);
  color: rgb(22, 106, 150);
  padding: 0.5rem 1rem;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  min-width: 100px;
  &:hover {
    background-color: #153755;
    color: white;
  }
`;

export const SelectBoxContainer = styled.div`
  width: 350px;
  display: flex;
  margin: 10px 26px 0px 10px;
  margin-left: auto;
  justify-content: space-between;
  align-items: center;
`;

export const SelectBoxWrapper = styled.div`
  flex: 1;
  margin-left: 10px;
`;

export const ToolTip = styled(ReactTooltip)`
  & .heading {
    display: flex;
  }
  &.custom-tooltip {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    color: black;
    width: 250px;
  }
`;

export const Dot = styled.div`
  height: 10px;
  width: 10px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
`;
