import React from 'react';
import { FooterOuter, TrademarkSection } from './Footer.style';

export const Footer = (): JSX.Element => {
  return (
    <FooterOuter data-test-footer>
      <TrademarkSection>
        Your use of the Pro Thermostat Pre-registration application is subject to our{' '}
        <a target='_blank' rel='noreferrer' href='https://pro.resideo.com/terms'>
          Pro Portal Terms and Conditions
        </a>{' '}
        and any other agreement we may have entered into. By accessing the Pro Thermostat Pre-registration application,
        you agree to be bound be these terms and conditions, both personally and on behalf of the company you represent.
      </TrademarkSection>
    </FooterOuter>
  );
};
