import { gql } from '@apollo/client';

const ComfortDeviceHandoffToConsumer = gql`
  mutation ComfortDeviceHandoffToConsumer($input: ComfortDeviceHandoffToConsumerArgumentsInput!) {
    ComfortDeviceHandoffToConsumer(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

export default ComfortDeviceHandoffToConsumer;
