import React, { useState } from 'react';
import { Formik } from 'formik';
import { InputField } from '@resideo/blueprint-formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Table, TableContainer } from 'components/Table/styles';
import { getAppointment, getCustomer, getCustomerData, getJob, getJobAppointmentData, getDeviceByEmail } from 'api';
import { dateFormatter, timeFormatter } from 'utils/datetime';
import PageLoading from 'components/common/PageLoading';
import { useToast } from 'components/common/Toast';
import { ErrorAlert } from 'components/common/Alert';
import { isEmpty } from 'lodash';
import light from 'themes/light';
import { GrCircleQuestion } from 'react-icons/gr';
import { CustomButton } from 'components/PreRegistration/styles';
import { CustomForm, NavigationButton } from '../styles';

export const StepOne = ({ setStep, regDeviceState, state: oldState }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({
    ...oldState,
  });
  const toast = useToast();

  const handleSubmit = async (e: any) => {
    try {
      setLoading(true);
      setData({});
      const { jobNumber } = e;
      const job = await getJob(jobNumber);
      if (isEmpty(job)) {
        setLoading(false);
        toast(ErrorAlert(t, 'Job not found!'));
        return;
      }
      const { id, customerId, firstAppointmentId } = job;

      const promises = [
        getAppointment(firstAppointmentId),
        getCustomer(customerId),
        getCustomerData(customerId),
        getJobAppointmentData(id),
      ];

      const [appointment, customer, customerData, appointmentData] = await Promise.all(promises);

      const { start, arrivalWindowEnd, arrivalWindowStart, status, end } = appointment;
      const { name: fullName, proPortalEnabled } = customer;
      const { value: email } = customerData;
      const { technicianName } = appointmentData;
      const { street, city, state, zip } = customer.address;
      const name = fullName.split(' ');

      const nameCopy = [...name];
      nameCopy.shift();
      const lastName = nameCopy.join(' ');

      const devices = await getDeviceByEmail(email, true);
      const relatedDeviceWithST = devices?.[0] || null;

      const body = {
        firstName: relatedDeviceWithST ? relatedDeviceWithST.hhFirstName : name[0],
        lastName: relatedDeviceWithST ? relatedDeviceWithST.hhLastName : lastName,
        email: relatedDeviceWithST ? relatedDeviceWithST.hhEmail : email,
        zip: relatedDeviceWithST ? relatedDeviceWithST.hhZip : zip,
        jobNumber,
        homeowner: `${street}, ${city}, ${state}, ${zip}`,
        technician: technicianName,
        jobStatus: status,
        start,
        end,
        state,
        locationName: relatedDeviceWithST ? relatedDeviceWithST.locationName : 'Home',
        deviceName: relatedDeviceWithST ? `Thermostat${devices.length + 1}` : 'Thermostat',
        arrivalWindowStart,
        arrivalWindowEnd,
        serviceTitanData: {
          fullName,
          email,
          zip,
          city,
          state,
          street,
        },
        relatedDeviceWithST,
        proPortalEnabled,
      };
      setData(body);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast(ErrorAlert(t, 'Something went wrong!'));
    }
  };

  const JobSearchSchema = Yup.object().shape({
    jobNumber: Yup.string()
      .matches(/^[0-9]+$/, 'Job Number cannot contain alpha or special characters.')
      .min(1, 'Job Number must have at least 1 character.')
      .max(12, 'Job Number cannot have more than 12 characters.')
      .required(t('common.validation.required')),
  });

  const {
    jobNumber,
    jobStatus,
    technician,
    start,
    end,
    arrivalWindowStart,
    arrivalWindowEnd,
    serviceTitanData = {},
    relatedDeviceWithST,
  } = data || {};

  const hasWindow = arrivalWindowStart && arrivalWindowEnd;
  const startDate = hasWindow ? arrivalWindowStart : start;
  const endData = hasWindow ? arrivalWindowEnd : end;
  return (
    <div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          jobNumber: jobNumber || '',
        }}
        validationSchema={JobSearchSchema}>
        {({ isSubmitting, resetForm }) => {
          return (
            <>
              <CustomForm>
                <InputField label='ServiceTitan Job #' name='jobNumber' placeholder='Enter Job #' />
                <NavigationButton disabled={isSubmitting} type='submit'>
                  Search
                </NavigationButton>
              </CustomForm>
              <div
                style={{
                  height: '230px',
                }}>
                {loading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'row',
                      flex: 1,
                      height: '230px',
                    }}>
                    <PageLoading />
                  </div>
                ) : (
                  data &&
                  !!Object.keys(data).length && (
                    <>
                      <TableContainer>
                        <Table
                          style={{
                            padding: 0,
                          }}
                          cellPadding='0'
                          cellSpacing='0'>
                          <thead>
                            <tr>
                              <th>Job #</th>
                              <th>First Appointment Arrival Window</th>
                              <th>Status</th>
                              <th>Customer</th>
                              <th>Technician</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{jobNumber}</td>
                              <td>
                                Start: {dateFormatter(startDate)} {timeFormatter(startDate)}
                                <br />
                                End: {dateFormatter(endData)} {timeFormatter(endData)}
                              </td>
                              <td>{jobStatus}</td>
                              <td>
                                <div>{serviceTitanData?.fullName}</div>
                                <div>
                                  <div>{serviceTitanData?.street}</div>
                                  <div>
                                    {serviceTitanData?.city}, {serviceTitanData?.state} {serviceTitanData?.zip}
                                  </div>
                                </div>
                                <br />
                                <div>{serviceTitanData?.email}</div>
                              </td>
                              <td>{technician}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </TableContainer>
                    </>
                  )
                )}
              </div>
              <div
                style={{
                  justifyContent: 'end',
                  alignItems: 'center',
                  display: 'flex',
                }}>
                {relatedDeviceWithST && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '110px',
                        maxWidth: '700px',
                      }}>
                      <GrCircleQuestion
                        stroke={light.colors.success}
                        size={80}
                        style={{ marginRight: 20, marginBottom: -4 }}
                      />
                      <p style={{ color: 'rgb(0, 169, 224)' }}>
                        An account with the above email address already exists. Do you want to proceed with adding a
                        device to this account?
                      </p>
                    </div>
                    <CustomButton
                      style={{ marginRight: '10px' }}
                      onClick={() => {
                        setData({});
                        resetForm({
                          values: {
                            jobNumber: '',
                          },
                        });
                      }}
                      disabled={Object.keys(data).length === 0}>
                      No
                    </CustomButton>
                  </>
                )}
                <NavigationButton
                  onClick={() => {
                    regDeviceState({
                      ...data,
                    });
                    setStep(2);
                  }}
                  disabled={Object.keys(data).length === 0}>
                  {relatedDeviceWithST ? 'Yes' : 'Next'}
                </NavigationButton>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
