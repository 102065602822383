import { useSuspenseQuery_experimental as useSuspenseQuery, gql } from '@apollo/client';
import { UseDeviceQuery } from '../__generated__/graphql';

export const UseDevice = gql`
  query UseDevice($deviceId: String!) {
    search(input: $deviceId) {
      ... on LyricThermostatDevice {
        id
        deviceId
        productId
        deviceInfo {
          deviceId
          deviceSerialNumber
          hardwareRevision
          osVersion
          softwareRevision
        }
      }
    }
  }
`;

export function useDevice(deviceId: string) {
  const {
    data: { search },
  } = useSuspenseQuery<UseDeviceQuery>(UseDevice, {
    variables: { deviceId },
  });

  return {
    ...search,
  };
}
