import { deletePrivilegeApi, fetchPrivilegeData, createPrivilegeData, updatePrivilegeData } from 'api';
import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import React, { useEffect, useState } from 'react';
import { useModal, InputField, Button } from '@resideo/blueprint-react';
import Select from 'react-select';
import styled from 'styled-components';
import { Table, TableContainer } from 'components/Table/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PageLoading from 'components/common/PageLoading';
import EditIcon from 'images/edit-icon.png';
import DeleteIcon from 'images/bin.png';
import { ErrorAlert, SuccessAlert } from 'components/common/Alert';
import { useToast } from 'components/common/Toast';
import { useTranslation } from 'react-i18next';

const AddButton = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: 25px;
`;

const FormContainer = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  margin-top: -50px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Form = styled.form`
  display: flex;
  width: 500px;
  height: auto;
  flex-direction: column;
  gap: 10px;
`;

const FormButtonWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 16px;
  justify-content: center;
`;

const PRIVILEGE_NAMES = {
  1: 'Bulk Sync with Pro Portal',
};

interface PrivilegeData {
  id: number;
  companyName: string;
  companyId: string;
  userName: string;
  userId: string;
  privilegeId: number;
}

const TableComponent = ({ setState, openModal, privilegeData, openDelModal, setDelPrivilege }) => {
  return (
    <TableContainer>
      <Table cellPadding='0' cellSpacing='0'>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Company ID</th>
            <th>User Name</th>
            <th>User ID</th>
            <th>Privilege</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {privilegeData.map(node => {
            const { id, companyName, companyId, userName, userId, privilegeId } = node;
            return (
              <tr key={id}>
                <td>{companyName}</td>
                <td>
                  {companyId?.substr(0, 25)}
                  {companyId?.length > 25 ? '...' : ''}
                </td>
                <td>{userName}</td>
                <td>
                  {userId?.substr(0, 25)}
                  {userId?.length > 25 ? '...' : ''}
                </td>
                <td>{PRIVILEGE_NAMES[privilegeId]}</td>
                <td>
                  <img
                    onClick={() => {
                      setState(node);
                      openModal();
                    }}
                    width={'15px'}
                    height={'15px'}
                    src={EditIcon}
                    title='Edit'
                    alt='edit icon'
                  />
                </td>
                <td>
                  <img
                    onClick={() => {
                      setDelPrivilege(node);
                      openDelModal();
                    }}
                    width={'15px'}
                    height={'15px'}
                    title='Delete'
                    src={DeleteIcon}
                    alt='edit icon'
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableContainer>
  );
};

const DetailsSchema = Yup.object().shape({
  companyName: Yup.string().required(),
  companyId: Yup.string().required(),
  userName: Yup.string().required(),
  userId: Yup.string().required(),
  privilegeId: Yup.string().required(),
});

const state = {
  companyName: '',
  companyId: '',
  userName: '',
  userId: '',
  privilegeId: 0,
};

interface RegistrationFormProps {
  closeModal: any;
  state?: any;
  setCompanyData: any;
}

const RegistrationForm = ({ closeModal, state: data = {}, setCompanyData }: RegistrationFormProps) => {
  const { t } = useTranslation();
  const toast = useToast();

  function handleCancel() {
    closeModal();
  }

  async function upsertFormData(e) {
    closeModal();
    try {
      if (e.id) {
        const response = await updatePrivilegeData(e);
        setCompanyData(prevState => {
          return prevState.filter(node => node.id !== response.id).concat(response);
        });
        toast(SuccessAlert(t, 'Update successful!'));
      } else {
        const response = await createPrivilegeData(e);
        console.log('🚀 ~ upsertFormData ~ response:', response);
        setCompanyData(prevState => {
          return prevState.filter(node => node.id !== response.id).concat(response);
        });
        toast(SuccessAlert(t, 'Create successful!'));
      }
    } catch (error) {
      toast(ErrorAlert(t, 'Create/Update failed!'));
    }
  }

  return (
    <div className='bp4-dialog-container'>
      <FormContainer>
        <h1>Add/Edit User Privilege</h1>
        <Formik
          validationSchema={DetailsSchema}
          initialValues={{ ...state, ...data }}
          onSubmit={e => {
            upsertFormData(e);
          }}>
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            values: { companyName, companyId, userName, userId, privilegeId },
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <InputField
                  label='Company Name'
                  type='text'
                  required
                  name='companyName'
                  onChange={handleChange}
                  value={companyName}
                />
                <InputField
                  label='Company ID'
                  required
                  type='text'
                  name='companyId'
                  onChange={handleChange}
                  value={companyId}
                />
                <InputField
                  label='User Name'
                  required
                  type='text'
                  name='userName'
                  onChange={handleChange}
                  value={userName}
                />
                <InputField label='User ID' required type='text' name='userId' onChange={handleChange} value={userId} />
                <SelectBoxContainer>
                  <label>Status *</label>
                  <SelectBoxWrapper>
                    <Select
                      value={{ value: +privilegeId, label: PRIVILEGE_NAMES[privilegeId] }}
                      options={Object.keys(PRIVILEGE_NAMES).map(key => ({ value: +key, label: PRIVILEGE_NAMES[key] }))}
                      onChange={option => {
                        setFieldValue('privilegeId', option?.value);
                      }}
                      className='privilege-select'
                      classNamePrefix='select'
                    />
                  </SelectBoxWrapper>
                </SelectBoxContainer>
                <FormButtonWrapper>
                  <Button type='button' onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type='submit'>Save</Button>
                </FormButtonWrapper>
              </Form>
            );
          }}
        </Formik>
      </FormContainer>
    </div>
  );
};

const DeleteModal = ({ closeModal, state: data = {}, setDelPrivilege, setState }: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { id, userName } = data;

  function handleCancel() {
    closeModal();
  }

  async function deletePrivilege() {
    try {
      await deletePrivilegeApi(id);
      setState(prevState => {
        return prevState.filter(node => node.id !== id);
      });
      toast(SuccessAlert(t, 'Delete successful!'));
    } catch (error) {
      console.log(error);
      toast(ErrorAlert(t, 'Update failed!'));
    } finally {
      closeModal();
      setDelPrivilege();
    }
  }

  return (
    <div
      style={{
        textAlign: 'center',
      }}
      className='bp4-dialog-container'>
      <p
        style={{
          marginBottom: '40px',
        }}>
        Are you sure you want to delete the Privilege for {userName}?
      </p>
      <Button
        style={{
          marginRight: '10px',
        }}
        variant='secondary'
        onClick={() => {
          setDelPrivilege(undefined);
          handleCancel();
        }}>
        Cancel
      </Button>
      <Button
        onClick={() => {
          deletePrivilege();
        }}
        variant='primary'>
        Delete
      </Button>
    </div>
  );
};

const Privilege = () => {
  const [privilegeData, setPrivilegeData] = useState<PrivilegeData[]>([]);
  const [deleteComp, setDelPrivilege] = useState<any>();
  const [loading, setLoading] = useState(false);

  const { Modal, openModal, closeModal } = useModal({
    newDesign: true,
  });

  const { Modal: DelModal, openModal: openDelModal, closeModal: closeDelModal } = useModal({
    newDesign: true,
  });

  const [state, setState] = useState();

  useEffect(() => {
    async function privilegeData() {
      setLoading(true);
      const data = await fetchPrivilegeData();
      setPrivilegeData(data);
      setLoading(false);
    }
    privilegeData();
  }, []);

  if (loading) return <PageLoading />;

  return (
    <DashboardLayout>
      <Modal>
        <RegistrationForm setCompanyData={setPrivilegeData} state={state} closeModal={closeModal} />
      </Modal>
      <DelModal>
        <DeleteModal
          setDelPrivilege={setDelPrivilege}
          setState={setPrivilegeData}
          state={deleteComp}
          closeModal={closeDelModal}
        />
      </DelModal>
      <AddButton>
        <Button
          variant={'primary'}
          onClick={() => {
            setState(undefined);
            openModal();
          }}>
          Add
        </Button>
      </AddButton>
      <TableComponent
        setDelPrivilege={setDelPrivilege}
        openDelModal={openDelModal}
        privilegeData={privilegeData}
        openModal={openModal}
        setState={setState}
      />
    </DashboardLayout>
  );
};

const SelectBoxContainer = styled.div`
  & > label {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    padding-bottom: 0.25rem;
    line-height: 1.5;
    font-size: 0.875rem;
    display: block;
  }
`;

const SelectBoxWrapper = styled.div`
  margin-left: 0px;
  & .select__control {
    border: 1px solid #4f7285;
    border-radius: 0.75rem;
    height: 41px;

    &:focus {
      box-shadow: 0 0 0 3px rgba(0, 169, 224, 0.2);
    }
  }
`;

export default Privilege;
